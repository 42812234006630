<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title *ngIf="editingComment">Edit Comment</h1>
        <h1 mat-dialog-title *ngIf="!editingComment">Add Comment</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="commentForm">
            <mat-form-field appearance="outline">
                <mat-label>Report Section</mat-label>
                <mat-select formControlName="report_section" required>
                    @for (section of reportSections; track reportSections) {
                    <mat-option [value]="section.pageNum">{{section.pageName}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field class="text-area" appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput formControlName="comment" placeholder="Enter description" required></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button *ngIf="editingComment" class="usa-button" [disabled]="!commentForm.valid"
                (click)="saveComment()">Update</button>
            <button *ngIf="!editingComment" class="usa-button" [disabled]="!commentForm.valid"
                (click)="saveComment()">Save</button>
        </div>
    </div>
</div>