<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>Update Self</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="userForm">
            <div class="subtitle"><span class="red">*</span>Indicates required field</div>
            <div class="title">USER DETAILS</div>
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="first_name" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="last_name" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="half-form">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phone_num" cdkFocusInitial placeholder="(XXX) XXX-XXXX" mask="(000) 000-0000">
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="data.user_type == 'User'">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency_id" required (selectionChange)="updateValidators()">
                    @for (agency of agency_list; track agency_list) {
                    <mat-option [value]="agency.value">{{agency.text}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <div *ngIf="userForm.value.agency_id == 0">
                <div class="title">Location<span class="gray-text">(Home unit)</span></div>
                <mat-radio-group required formControlName="location_id" class="radio-buttons" (change)="updateValidators()">
                    <mat-radio-button [value]="0" color="primary" class="radio-button">Forest Office</mat-radio-button>
                    <mat-radio-button [value]="1" color="primary">Regional Office</mat-radio-button>
                    <mat-radio-button [value]="2" color="primary">Washington Office</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="userForm.value.location_id == 0 || userForm.value.location_id == 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Region</mat-label>
                        <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                            @for (region of regions; track region) {
                            <mat-option [value]="region">{{region}}</mat-option>
                            }
                        </mat-select>
                        <mat-error>Required Field</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="userForm.value.location_id == 0">
                        <mat-label>Forest Name</mat-label>
                        <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                        (input)="filter()"
                        (focus)="filter()">
                        <mat-autocomplete requireSelection #auto="matAutocomplete"> 
                            @for (name of filtered_forest_names | async; track name) {
                            <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                            }
                        </mat-autocomplete>
                        <mat-error>Required Field</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field appearance="outline" style="max-width: 100%; width:96%">
                <mat-label>Position<span class="gray-text">(Select all that apply)</span></mat-label>
                <mat-select formControlName="positions" required multiple>
                    @for (role of roles; track role) {
                    <mat-option [value]="role.value">{{role.text}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button class="usa-button" [disabled]="!userForm.valid" (click)="saveUser()">Update</button>
        </div>
    </div>
</div>