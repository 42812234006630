import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Editor, Toolbar } from 'ngx-editor';
import { Subject } from 'rxjs';
import { StateCounty } from 'src/app/models/stateCounty.model';
import { Watershed } from 'src/app/models/watershed.model';
import { ReportService } from 'src/app/services/report.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { ReportForest } from 'src/app/models/reportForest.model';

@Component({
  selector: 'app-burned-area',
  templateUrl: './burned-area.component.html',
  styleUrls: ['./burned-area.component.scss']
})


export class BurnedAreaComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  im = new Inputmask({
    groupSeparator: ',',
    prefix: '$',
    allowMinus: false
  });

  editor1!: Editor;
  editor2!: Editor;
  editor3!: Editor;
  editor4!: Editor;
  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]
  html =  "<p>Hello</p>"

  report = input.required<any>();
  nav = input.required<string>();
  forests: ReportForest[] = []
  counties: string[] = [];
  states: string[] = [];
  saveSubject = new Subject<any>();

  // close = input.required<boolean>();

  streamTypes = [
    "Perennial",
    "Intermittent",
    "Ephemeral",
    "Canal/Ditch/Pipeline",
  ]

  fieldDict: any = {
    "Perennial":"stream_miles_perennial",
    "Intermittent":"stream_miles_intermittent",
    "Ephemeral":"stream_miles_ephemeral",
    "Canal/Ditch/Pipeline":"stream_miles_canal",
    "1 - Basic Custodial Care (Closed)":"roads_miles_basic",
    "2 - High Clearance Vehicles":"roads_miles_clearance",
    "3 - Suitable For Passenger Cars":"roads_miles_passenger",
    "4 - Moderate Degree of User Comfort":"roads_miles_moderate",
    "5 - High Degree of User Comfort":"roads_miles_high",
    "Unknown or Decommissioned":"roads_miles_unknown",
    "Motorized":"transport_miles_motorized",
    "Non-motorized":"transport_miles_non",
  }

  maintenanceLevels = [
    "1 - Basic Custodial Care (Closed)",
    "2 - High Clearance Vehicles",
    "3 - Suitable For Passenger Cars",
    "4 - Moderate Degree of User Comfort",
    "5 - High Degree of User Comfort",
    "Unknown or Decommissioned",
  ]

  trailTypes = [
    "Motorized",
    "Non-motorized",
  ]

  form = this.formBuilder.group({
    baer_name: [""],
    fire_number: [""],
    job_code: ["", Validators.maxLength(2)],
    fire_discovery_date: [null] as unknown as Date[],
    containment_date_est: [null] as unknown as Date[],
    containment_date: [null] as unknown as Date[],

    subwatershed: this.formBuilder.array([]),
    acresBurned: this.formBuilder.array([]),
    streams: this.formBuilder.array([]),
    maintenance: this.formBuilder.array([]),
    trails: this.formBuilder.array([]),

    vegetation_types: [""],
    dominant_soils: [""],
    geologic_types: [""],
    other_roads: [""],
  });
  get subwatershed() {
    return this.form.get("subwatershed") as FormArray;
  }
  get acresBurned() {
    return this.form.get("acresBurned") as FormArray;
  }
  get streams() {
    return this.form.get("streams") as FormArray;
  }
  get maintenance() {
    return this.form.get("maintenance") as FormArray;
  }
  get trails() {
    return this.form.get("trails") as FormArray;
  }

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,    
    private readonly dialogService: DialogService,    
    private readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {


    this.editor1 = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.forests = this.report().forests
    this.counties = this.report().stateCounties.map((county: StateCounty) => county.county_name)
    this.states = this.report().stateCounties.map((state: StateCounty) => state.state_name)
    this.buildTableForms()

    this.form.patchValue({
      baer_name: this.report().baer_name,
      fire_number: this.report().IRWIN_unique_fire_identifier,
      job_code: this.report().job_code,
      fire_discovery_date: this.report().fire_discovery_date,
      containment_date_est: this.report().containment_date_est,
      containment_date: this.report().containment_date,

      vegetation_types: this.report().vegetation_types ?? "",
      dominant_soils: this.report().dominant_soils ?? "",
      geologic_types: this.report().geologic_types ?? "",
      other_roads: this.report().other_roads,
    })
  }

  buildTableForms(): void {
    this.report().watersheds.forEach((watershed: Watershed) => {
      this.subwatershed.push(this.formBuilder.group({
        HU12: watershed.huc12,
        name: watershed.huc12_name,
        totalAcres: watershed.acres_total,
        acresBurned: watershed.acres_burned,
        percentBurned: watershed.percent_burned,
        watershed_id: watershed.watershed_id,
      }))
    })

    for (let forest of this.forests) {
      let questions1: any = {
        forest_id: forest.forest_id
      }
      for (let stream of this.streamTypes) {
        questions1[stream] = [(forest as any)[this.fieldDict[stream]]];
      }
      this.streams.push(this.formBuilder.group(questions1))
      let questions2: any = {"other": 0}
      for (let level of this.maintenanceLevels) {
        questions2[level] = [(forest as any)[this.fieldDict[level]]];
      }
      this.maintenance.push(this.formBuilder.group(questions2))
      let questions3: any = {}
      for (let trail of this.trailTypes) {
        questions3[trail] = [(forest as any)[this.fieldDict[trail]]];
      }
      this.trails.push(this.formBuilder.group(questions3))
    }
    for (let data of this.report().soil_burn_severities) {
      let existing = this.acresBurned.controls.find((item: any) => {
        return item.value.name == data.ownership
      })
      if (existing) {
        continue
      }

      this.acresBurned.push(this.formBuilder.group({
        name: data.ownership,
        acresBurned: data.acres_burned,
        percentBurned: data.percent_burned,
        report_soil_burn_severity_id: data.report_soil_burn_severity_id,
      }))
    }
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 3 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 1 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    this.saveSubject.next(null)
    
    if (!this.form.dirty) {
      // Don't save if nothing has changed
      return
    }
    let allFieldsFilled = true

    for (let field in this.form.controls) {
      if (this.form.get(field)?.value === null || this.form.get(field)?.value === "") {
        allFieldsFilled = false
      }
    } 

    let watersheds: Watershed[] = this.subwatershed.controls.map((watershed: any) => {
      return {
        huc12: watershed.value.HU12,
        huc12_name: watershed.value.name,
        acres_total: watershed.value.totalAcres,
        acres_burned: watershed.value.acresBurned,
        percent_burned: watershed.value.percentBurned,
        watershed_id: watershed.value.watershed_id,
        report_id: this.report().report_id,
      } as Watershed
    });

    let forests: ReportForest[] = []
    for (let index=0; index<this.streams.length; index++) {
      forests.push({
        forest_id: (this.streams.controls[index] as any).value["forest_id"],
        stream_miles_perennial: (this.streams.controls[index] as any).value["Perennial"],
        stream_miles_intermittent: (this.streams.controls[index] as any).value["Intermittent"],
        stream_miles_ephemeral: (this.streams.controls[index] as any).value["Ephemeral"],
        stream_miles_canal: (this.streams.controls[index] as any).value["Canal/Ditch/Pipeline"],
        roads_miles_basic: (this.maintenance.controls[index] as any).value["1 - Basic Custodial Care (Closed)"],
        roads_miles_clearance: (this.maintenance.controls[index] as any).value["2 - High Clearance Vehicles"],
        roads_miles_passenger: (this.maintenance.controls[index] as any).value["3 - Suitable For Passenger Cars"],
        roads_miles_moderate: (this.maintenance.controls[index] as any).value["4 - Moderate Degree of User Comfort"],
        roads_miles_high: (this.maintenance.controls[index] as any).value["5 - High Degree of User Comfort"],
        roads_miles_unknown: (this.maintenance.controls[index] as any).value["Unknown or Decommissioned"],
        transport_miles_motorized: (this.trails.controls[index] as any).value["Motorized"],
        transport_miles_non: (this.trails.controls[index] as any).value["Non-motorized"],
      })
    }

    let soils = this.acresBurned.value.map((item: any) => {
      console.log("item",item,item.value)
      let data = {
        report_id: this.report().report_id,
        report_soil_burn_severity_id: item.report_soil_burn_severity_id,
        ownership: item.name,
        acres_burned: item.acresBurned,
        percent_burned: +item.percentBurned,
      }
      return data
    })


    
    this.reportService.updateReport({
      report_id: this.report().report_id,
      baer_name: this.form.value.baer_name as string | undefined,
      IRWIN_unique_fire_identifier: this.form.value.fire_number as number | undefined,
      job_code: this.form.value.job_code as number | undefined,

      fire_discovery_date: this.form.value.fire_discovery_date ? this.formatDate(new Date(this.form.value.fire_discovery_date)) : undefined,
      containment_date_est: this.form.value.containment_date_est ? this.formatDate(new Date(this.form.value.containment_date_est)) : undefined,
      containment_date: this.form.value.containment_date ? this.formatDate(new Date(this.form.value.containment_date)) : undefined,

      vegetation_types: this.form.value.vegetation_types as string | undefined,
      dominant_soils: this.form.value.dominant_soils as string | undefined,
      geologic_types: this.form.value.geologic_types as string | undefined,
      other_roads: this.form.value.other_roads as string | undefined,

      watersheds: watersheds,
      forests: forests,
      soil_burn_severities: soils,
      page_2_status_id: allFieldsFilled? 2 : 1,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.saveSubject.next(null)
    this.save()
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }

  sumRoadMiles(i: number) {
    let values = (this.form.value.maintenance as any)[i]
    values["other"] = 0
    let total = 0
    for (let key in values) {
      total += +(values[key] ?? 0)
    }
    return total
  }

  sumStream(stream: string) {
    let total = 0
    this.form.value.streams?.forEach((item: any) => {
      total += +(item[stream] ?? 0)
    })
    return total
  }

  sumTrail(trail: string) {
    let total = 0
    this.form.value.trails?.forEach((item: any) => {
      total += +(item[trail] ?? 0)
    })
    return total
  }

  sumAcresBurned() {
    let total = 0
    this.form.value.acresBurned?.forEach((item: any) => {
      total += +(item.acresBurned ?? 0)
    })
    return total
  }

  addWaterShed() {
    this.subwatershed.push(this.formBuilder.group({
      HU12: "",
      name: "",
      totalAcres: null,
      acresBurned: null,
      percentBurned: null,
    }))
  }

  formatDate(date: Date) {
    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }
}
