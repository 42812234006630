<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">4. Summary of Analysis</h2>

    <mat-card [formGroup]="form" style="margin-bottom: 24px;">
        <div class="text-div">
            <b><span class="red">*</span>Introduction/Background</b>
            <br>
            <span class="gray-text">Provide a brief introduction and/or background of the fire.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="background" [editor]="editor1" placeholder="Enter Description"></ngx-editor>
        </div>
    </mat-card>

    <mat-card [formGroup]="form" style="margin-bottom: 24px;">

        <div class="sub-title">
            Critical Values, Risks, Threats & Resources
        </div>

        <div class="border-div"></div>

        <div class="text-div">
            <b><span class="red">*</span>Critical Values File link</b>
            <span class="gray-text"> Add the Box link to the critical values file.</span>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <input matInput formControlName="critical_values" required aria-label="Fire or Complex Name" placeholder="https://www.box.com/link" >
                <mat-error>Please enter a valid URL</mat-error>
            </mat-form-field>
        </div>

        <div class="text-div" style="padding-top: 4px;">
            <b>Critical Values Highlights Summary</b>
            <br><br>
            <span class="gray-text">
                Describe Critical Values/Resources and Threats. Provide a brief narrative summary of the critical values (life, safety, property, natural and cultural resources) and risk ratings, providing a rationale for the risk determinations.
                <br><br>
                When the Burned Area Emergency Response (BAER) team determines that emergency treatments are not necessary, summarize the rationale and the watershed conditions which led to this conclusion.
            </span>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Human Life and Safety</b>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor2" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="threats_human" [editor]="editor2" placeholder="Enter Description"></ngx-editor>
        </div>
        <image-upload
            [report_id]="report().report_id"
            [supporting_files]="report().supporting_files"
            [saveSubject]="saveSubject"
            section="4"
            question="human_life"
        ></image-upload>

        <div class="text-div">
            <b><span class="red">*</span>Property</b>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor3" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="threatsproperty" [editor]="editor3" placeholder="Enter Description"></ngx-editor>
        </div>
        <image-upload
            [report_id]="report().report_id"
            [supporting_files]="report().supporting_files"
            [saveSubject]="saveSubject"
            section="4"
            question="property"
        ></image-upload>

        <div class="text-div">
            <b><span class="red">*</span>Natural Resources</b>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor4" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="threats_natural" [editor]="editor4" placeholder="Enter Description"></ngx-editor>
        </div>
        <image-upload
            [report_id]="report().report_id"
            [supporting_files]="report().supporting_files"
            [saveSubject]="saveSubject"
            section="4"
            question="natural_res"
        ></image-upload>

        <div class="text-div">
            <b><span class="red">*</span>Cultural and Heritage Resources</b>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor5" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="threats_cultural" [editor]="editor5" placeholder="Enter Description"></ngx-editor>
        </div>
        <image-upload
            [report_id]="report().report_id"
            [supporting_files]="report().supporting_files"
            [saveSubject]="saveSubject"
            section="4"
            question="cultural_res"
        ></image-upload>

    </mat-card>

    <mat-card [formGroup]="form" style="padding-bottom: 24px;">

        <div class="text-div">
            <b>Probability of Completing Treatment Prior to Damaging Storm or Event</b>
            <br>
            <span class="gray-text">
                Probability of completion prior to the first major storm event. Estimated to the nearest 10 percent.
            </span>
        </div>
        <!-- TABLE Ownership -->
        <div class="table-container">
            <table>
                <tr class="table-header">
                    <th>Treatment Type</th>
                    <th class="right">Probability</th>
                </tr>
                <tr class="table-row">
                    <td class="text-cell">Land</td>
                    <td>
                        <mat-form-field appearance="outline" class="example small-field">
                            <input class="right" formControlName="treat_prob_land" matInput aria-label="treatment land" mask="00" suffix="%"
                                >
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="text-cell">Channel</td>
                    <td>
                        <mat-form-field appearance="outline" class="example small-field">
                            <input class="right" formControlName="treat_prob_channel" matInput aria-label="treatment land" mask="00" suffix="%"
                                >
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="text-cell">Road Trails</td>
                    <td>
                        <mat-form-field appearance="outline" class="example small-field">
                            <input class="right" formControlName="treat_prob_trails" matInput aria-label="treatment land" mask="00" suffix="%"
                                >
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="text-cell">Protection Safety</td>
                    <td>
                        <mat-form-field appearance="outline" class="example small-field">
                            <input class="right" formControlName="treat_prob_safety" matInput aria-label="treatment land" mask="00" suffix="%"
                                >
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Cost of No-Action (Including Loss)</b>
            <br>
            <span class="gray-text">Enter the cost of the no-action or baseline alternative as calculated using an appropriate cost-benefit analysis tool.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor6" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="cost_no_action" [editor]="editor6" placeholder="Enter Description"></ngx-editor>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Cost of Selected Alternative (Including Loss)</b>
            <br>
            <span class="gray-text">Enter the cost of the selected alternative as calculated using an appropriate cost-benefit analysis tool.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor7" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor formControlName="cost_alternative" [editor]="editor7" placeholder="Enter Description"></ngx-editor>
        </div>

    </mat-card>
</main>