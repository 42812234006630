import { Pipe } from "@angular/core";

@Pipe({ name: "byte" })
export class BytePipe {
    transform(rawNum: number): string {
        if (rawNum >= 1000000) {
            return  Math.round(rawNum/1000000) + "MB"
        } else if (rawNum >= 1000) {
            return  Math.round(rawNum/1000) + "KB"
        }
        return rawNum + "B";
    }
}