<form id="position_form" class="filter_form" [formGroup]="positionForm">
    <div class="subtitle" style="margin-top: 10px;">
        <span class="red">*</span>Position's Location
    </div>
    <mat-radio-group required formControlName="location_shared" class="radio-buttons"
        (change)="updateValidators($event.value, 'location_shared')">
        <mat-radio-button [value]="0" color="primary" class="radio-button">Same as Organizational Unit's
            Location</mat-radio-button>
        <mat-radio-button [value]="1" color="primary">Same as Duty Station's Location</mat-radio-button>
        <mat-radio-button [value]="2" color="primary">Other Location</mat-radio-button>
    </mat-radio-group>
    <div class="sub-location" *ngIf="positionForm.controls['location_shared'].getRawValue() == 2">
        <div class="subtitle" style="margin: 0;">
            Select Location:
        </div>
        <mat-radio-group required formControlName="location_id" class="radio-buttons"
            (change)="updateValidators($event.value, 'location_id')">
            <mat-radio-button [value]="0" color="primary" class="radio-button">Forest Office</mat-radio-button>
            <mat-radio-button [value]="1" color="primary">Regional Office</mat-radio-button>
            <mat-radio-button [value]="2" color="primary">Washington Office</mat-radio-button>
        </mat-radio-group>
        <div
            *ngIf="positionForm.controls['location_id'].getRawValue() == 0 || positionForm.controls['location_id'].getRawValue() == 1">
            <mat-form-field appearance="outline">
                <mat-label>Region</mat-label>
                <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                    @for (region of regions(); track region) {
                    <mat-option [value]="region">{{region}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="positionForm.controls['location_id'].getRawValue() == 0"
                style="width: 50%">
                <mat-label>Forest Name</mat-label>
                <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                    (input)="filter()" (focus)="clearForest()">
                <mat-autocomplete requireSelection #auto="matAutocomplete"
                    (optionSelected)="forestNameSelected($event)">
                    @for (name of filtered_forest_names | async; track name) {
                    <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                    }
                </mat-autocomplete>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="sub-location"
        *ngIf="positionForm.controls['location_shared'].getRawValue() == 0 && org_location() != 2">
        <div class="read-only">
            <div class="region">
                <div class="region-row1">Region</div>
                <div class="region-row2">{{org_region() ?? ''}}</div>
            </div>
            <div class="forest" *ngIf="org_location() == 0">
                <div class="forest-row1">Forest</div>
                <div class="forest-row2">{{org_forest() ?? ''}}</div>
            </div>
        </div>
    </div>
    <div class="sub-location"
        *ngIf="positionForm.controls['location_shared'].getRawValue() == 1 && duty_location() != 2">
        <div class="read-only">
            <div class="region">
                <div class="region-row1">Region</div>
                <div class="region-row2">{{duty_region() ?? ''}}</div>
            </div>
            <div class="forest" *ngIf="duty_location() == 0">
                <div class="forest-row1">Forest</div>
                <div class="forest-row2">{{duty_forest() ?? ''}}</div>
            </div>
        </div>
    </div>
    <mat-form-field appearance="outline" *ngIf="user_type() == 'Coordinator'" style="max-width: 100%; width:96%">
        <mat-label>Position<span class="gray-text">(Select all that apply)</span></mat-label>
        <mat-select formControlName="positions" required multiple>
            @for (role of location().filtered_roles; track role) {
            <mat-option [value]="role.value">{{role.text}}</mat-option>
            }
        </mat-select>
        <mat-error>Required Field</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="user_type() == 'Line Officer'" style="max-width: 100%; width:96%">
        <mat-label>Line Officer Position</mat-label>
        <mat-select formControlName="lo_position" required>
            @for (role of location().filtered_roles; track role) {
            <mat-option [value]="role.value">{{role.text}}</mat-option>
            }
        </mat-select>
        <mat-error>Required Field</mat-error>
    </mat-form-field>
</form>