import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { faEdit, faFire } from '@fortawesome/free-solid-svg-icons';
import { NewFireComponent } from 'src/app/routes/fire-tracker/new-fire/new-fire.component';
import { MatTableDataSource } from '@angular/material/table';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MergeFiresComponent } from 'src/app/routes/fire-tracker/merge-fires/merge-fires.component';
import { Observable, Subject, map, of, startWith, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ComplexityModalComponent } from './complexity-modal/complexity-modal.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fire-tracker',
  templateUrl: './fire-tracker.component.html',
  styleUrls: ['./fire-tracker.component.scss']
})


export class FireTrackerComponent implements OnInit, OnDestroy {
  faEdit = faEdit;
  faFire = faFire;
  
  pageNumber = 0;
  pageSize = 10;
  length = 0;
  sort = "updated_on"
  sortDirection = "desc"
  selectedForestFlag = false;

  regions: any;
  statuses: any;
  complexities: any;

  forestNameList!: string[];
  filtered_forest_names!: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  forest_name = new FormControl("")

  fires = new MatTableDataSource<any>();
  mergeFires?: Array<object>;
  displayedColumns!: string[];
  selectedRegion: string | undefined = undefined;

  filters = {
    region: null,
    forest_name: null as (string | null),
    baer_name: null,
    assessment_status_id: null,
    fire_complexity_id: null,
  }
  filters_clean = true;

  last_updated_on: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    public dialog: MatDialog,
    private readonly assessmentService: AssessmentService,
    public readonly _snackBar: MatSnackBar,
    private readonly dialogService: DialogService,
    private readonly titleService: Title
  ) {
    this.titleService.setTitle("BAER - Fire Tracker")
    this.setLists();
  }

  setLists() {
    this.regions = this.assessmentService.getRegionList();
    this.statuses = this.assessmentService.getStatusList().filter((x: any) => [0, 1, 2, 3].includes(x.value));
    this.complexities = this.assessmentService.getComplexityList();
    this.forestNameList = this.assessmentService.getForestNameList();
    this.filtered_forest_names = of(this.forestNameList)

    this.assessmentService.finishLoadingSubscription().subscribe(() => {
      this.regions = this.assessmentService.getRegionList();
      this.statuses = this.assessmentService.getStatusList().filter((x: any) => [0, 1, 2, 3].includes(x.value));
      this.complexities = this.assessmentService.getComplexityList();
      this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);

      this.filtered_forest_names = of(this.forestNameList)
      this.filtered_forest_names = this.forest_name.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter((value as string) || ""))
      )
    })
  }

  regionChanged(): void {
    this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnDestroy() {
    this._snackBar.dismiss();
  }

  ngOnInit(): void {
    this.loadAssessments()

    this.displayedColumns = ['merge',
      'source',
      'region',
      'forest_org_code',
      'baer_name',
      'forest_name',
      'acres_fsonly', // labeled as est_nfs_acres
      'total_fire_acres',
      'containment_date', // labeled as containment_date_est
      'assessment_status_id',
      'fire_complexity_id',
      'actions'
    ]
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  private loadAssessmentMetadata() {
    this.assessmentService.getAssessmentMetadata().subscribe(metadata => this.last_updated_on.next(metadata?.updated_on));
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forestNameList.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  search(): void {
    this.filters_clean = false;
    this.pageNumber = 0;
    this.loadAssessments();
  }

  clearSearch(): void {
    this.filters_clean = true;
    this.pageNumber = 0;
    this.filters = {
      region: null,
      forest_name: null,
      baer_name: null,
      assessment_status_id: null,
      fire_complexity_id: null,
    }
    this.forest_name.setValue("");
    this.regionChanged();
    this.loadAssessments();
  }

  pageEvent(e: any) {
    this.pageNumber = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadAssessments();
  }

  loadAssessments() {
    this.dialogService.openSpinner()
    this.filters.forest_name = this.forest_name.value ?? null;
    this.assessmentService.getAssessmentList(this.pageNumber + 1, this.pageSize, this.filters, this.sort, this.sortDirection).subscribe(
      {
        next: (data) => {
          this.fires.data = data.assessments;
          this.length = data.totalResults;
        },
        complete: () => {
          this.dialogService.closeSpinner()
          this.loadAssessmentMetadata();
        }
      });
  }

  sortChange(sortState: any) {
    this.sort = sortState.active;
    this.sortDirection = sortState.direction;
    this.pageNumber = 0;
    this.loadAssessments()
  }

  addNewFire(): void {
    this.dialog.open(NewFireComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Fire Tracker")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  complexityModal(): void {
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  editFire(fire: any): void {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: fire,
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Fire Tracker")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  mergeCheck() { //triggered by merge checkbox click
    this.openSnackBar();
  }

  openSnackBar() {
    this.mergeFires = this.fires.data.filter(x => x.isSelected === true)
    if (this.mergeFires.length > 0) {
      let subject = new Subject()
      this._snackBar.openFromComponent(MergeSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        data: {
          length: this.mergeFires.length,
          mergeFires: this.mergeFires,
          subject: subject
        }
      })
      subject.asObservable().subscribe(reload => {
        if (reload) {
          this.loadAssessments();
        }
      })
    }
    else {
      this._snackBar.openFromComponent(MergeSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
        data: {
          length: 0,
        }
      });
    }
  }

  clearValue(event: any) {
    event.target.value = ""
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.forest_name.setValue("")
    }
    this.selectedForestFlag = false
  }

  forestSelected() {
    this.selectedForestFlag = true
  }
}
@Component({
  selector: 'merge-snackbar',
  templateUrl: 'merge-snackbar.html',
  styleUrls: ['./merge-snackbar.component.scss']
})
export class MergeSnackbarComponent {
  length = this.data.length;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public dialog: MatDialog,
    private readonly titleService: Title
  ) { }
  snackBarRef = inject(MatSnackBarRef);

  mergeDialog() {
    this.dialog.open(MergeFiresComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.data.mergeFires,
      }
    }).afterClosed().subscribe((confirmed) => {
      this.titleService.setTitle("BAER - Fire Tracker");
      this.data.subject.next(confirmed);
      this.clearSelected();
    })
  }

  clearSelected() {
    this.data.mergeFires.forEach((fire: any) => fire.isSelected = false)
  }
}
