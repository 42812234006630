<span class="merge-snackbar"
    style="height: 61px; font-size: 16; font-weight: 600; padding: 20px;" matSnackBarLabel>
    {{length}} user(s) selected
</span>
<span matSnackBarActions>
    <button *ngIf="length != 0 && allow_delete && canDelete" (click)="deleteUsers()"
        style="height: 40px;font-weight: 700;" (click)="snackBarRef.dismissWithAction()"
        color="accent" class="usa-button delete">
        <i class="material-icons">delete</i>
        Delete</button>
    <button *ngIf="length != 0 && allow_remove" (click)="removeUsers()"
        style="height: 40px;font-weight: 700;" (click)="snackBarRef.dismissWithAction()"
        color="accent" class="usa-button remove">
        <i class="material-icons">person_off</i>
        Remove</button>
    <button mat-icon-button class="exit_button" style="min-width: 0px;" (click)="snackBarRef.dismiss(); clearList()">
        <mat-icon style="color: #ffffff;">close</mat-icon>
    </button>
</span>