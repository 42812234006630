import { Component, OnInit, input } from '@angular/core';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReportService } from 'src/app/services/report.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { Subject } from 'rxjs/internal/Subject';

type ThumbnailInfo = {
  image?: string,
  supporting_files_id?: number,
  name: string,
  size: number,
  extension: string,
  control?: FormControl,
  progress?: number,
  canceled?: boolean,
}

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})


export class ImageUploadComponent implements OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  section = input.required<string>();
  question = input.required<string>();
  supporting_files = input.required<any>();
  report_id = input.required<string>();

  thumbnails: any[] = [];

  fileTypeMatch = /^.*\.(bmp|tiff|png|jpeg|jpg|gif|x-ms-bmp)$/;

  dropZoneHovered = false;

  saveSubject = input.required<Subject<any>>();


  constructor(
    public readonly reportService: ReportService,
    private readonly toast: ConfirmationToastComponent,
    private readonly formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.saveSubject().asObservable().subscribe(() => {
      this.saveCaptions()
    })
    this.supporting_files().forEach((fileInfo: any) => {
      if (fileInfo.section == this.section() && fileInfo.question == this.question()) {
        this.loadThumbnail(fileInfo)
      }
    })
  }

  /**
   * Load thumbnail from database. Not using url directly as we need authentication.
   */
  loadThumbnail(fileInfo: any, thumbnailPlaceholder?: ThumbnailInfo) {
    this.reportService.getThumbnail(fileInfo.supporting_files_id).subscribe({
      next: (response) => {
        let src = URL.createObjectURL(response)
        if (thumbnailPlaceholder) {
          thumbnailPlaceholder.supporting_files_id = fileInfo.supporting_files_id;
          if (fileInfo.name) {
            thumbnailPlaceholder.name = fileInfo.file_name.substring(37) // remove extra characters from name
          }
          thumbnailPlaceholder.progress = 1
          thumbnailPlaceholder.control = this.formBuilder.control("", Validators.maxLength(150))
          setTimeout(() => {
            thumbnailPlaceholder.image = src;
            thumbnailPlaceholder.progress = undefined
          }, 1000)
        } else {
          let fileName = fileInfo.file_name.substring(37) // remove extra characters from name
          let item: ThumbnailInfo = {
            image: src,
            supporting_files_id: fileInfo.supporting_files_id,
            name: fileName,
            size: fileInfo.image_size,
            extension: this.getFileExtension(fileName),
            control: this.formBuilder.control(fileInfo.caption ?? "", Validators.maxLength(150))
          }
          this.thumbnails.push(item)
        }
      },
      error: (response) => {
        console.log("err", response)
        let fileName = ""
        if (thumbnailPlaceholder) {
          fileName = thumbnailPlaceholder.name
          thumbnailPlaceholder.progress = undefined
        } else {
          fileName = fileInfo.file_name.substring(37) // remove extra characters from name
        }
        this.toast.openErrorSnackBar(`There was an error displaying file ${fileName}`);
      },
    })
  }

  /**
   * Update progress bar every second until progress is 1. Will not complete until it gets response from api
   */
  loadingProgress(thumbnailInfo: ThumbnailInfo) {
    let callback = function () {
      if (thumbnailInfo.progress === undefined || thumbnailInfo.progress >= 1) {
        return
      }
      if (thumbnailInfo.progress < .5) {
        thumbnailInfo.progress += (0.49 - thumbnailInfo.progress) * .1
      } else {
        thumbnailInfo.progress += (0.9 - thumbnailInfo.progress) * .1
      }
      setTimeout(callback, 1000)
      return 0
    }
    callback()
  }

  /**
   * Upload file after drag & drop or file selected
   * @param event event context
   */
  onFileSelected(event: any) {
    this.dropZoneHovered = false;
    for (let file of event.target.files) {
      if (file.size > 64000000) {
        console.log("File too big")
        this.toast.openErrorSnackBar(`Uploaded file ${file.name} is too large.`, "Please limit file sizes to 64MB");
        continue
      }
      if (!this.fileTypeMatch.test(file.name)) {
        console.log("file type is invalid")
        this.toast.openErrorSnackBar(`The file type for ${file.name} is invalid.`, "Accepted file types are bmp, tiff, png, jpeg, jpg, gif, x-ms-bmp");
        continue
      }
      let formData = new FormData();
      formData.append("attachment", file)
      formData.append("section", this.section())
      formData.append("question", this.question())
      formData.append("report_id", this.report_id())
      formData.append("image_size", file.size)


      let thumbnailPlaceholder: ThumbnailInfo = {
        name: file.name,
        progress: 0,
        size: file.size,
        extension: this.getFileExtension(file.name),
      }

      this.thumbnails.push(thumbnailPlaceholder)

      this.reportService.uploadFile(formData).subscribe({
        next: (response) => {
          if (response.supporting_files_id) {
            if (thumbnailPlaceholder.canceled) {
              // Don't load image if canceled checked
              thumbnailPlaceholder.supporting_files_id = response.supporting_files_id
              this.delete(thumbnailPlaceholder)
              return
            }
            thumbnailPlaceholder.progress = .5;
            this.loadThumbnail({
              supporting_files_id: response.supporting_files_id
            }, thumbnailPlaceholder);
          }
        },
        error: (response) => {
          thumbnailPlaceholder.progress = undefined; // stop progress bar
          this.toast.openErrorSnackBar(`There was an error uploading file ${file.name}`, "Please try again.");
          console.log("err", response)
        }
      })
    }
  }

  /**
   * Delete supporting file from database
   * @param thumbnailInfo 
   */
  delete(thumbnailInfo: ThumbnailInfo) {
    if (thumbnailInfo.supporting_files_id === undefined) {
      thumbnailInfo.canceled = true;
      return
    }
    this.reportService.deleteFile(thumbnailInfo.supporting_files_id).subscribe({
      next: (response) => {
        let index = this.thumbnails
          .findIndex((item: any) => thumbnailInfo.supporting_files_id == item.supporting_files_id)
        if (index != -1) {
          this.thumbnails.splice(index, 1)
        }
      },
      error: (response) => {
        console.log("err", response)
      }
    })
  }

  getFileExtension(fileName: string): string {
    return (/(?:\.([^.]+))?$/.exec(fileName) ?? "")[1].toString().toUpperCase()
  }

  /**
   * Saving captions. Triggered on page leave
   */
  saveCaptions(): void {
    this.thumbnails.forEach((thumbnail: ThumbnailInfo) => {
      if (thumbnail.control?.touched) {
        this.reportService.updateFile(thumbnail.supporting_files_id as number, {
          report_id: this.report_id(),
          caption: thumbnail.control.value
        }).subscribe({
          next: (response) => {},
          error: (response) => {
            console.log("err", response)
          }
        })
      }
    })
  }
}
