import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';
import { faEdit, faFileLines, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { RequestSupportComponent } from '../request-support/request-support.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})


export class ReportListComponent implements OnInit {
  faEdit = faEdit;
  faUsers = faUsers;
  faWarning = faWarning;
  faFileLines = faFileLines;
  reports = new MatTableDataSource<any>();
  displayedColumns: string[] = ['filler', 'name', 'reportId', 'reportStatus', 'baerTeam', 'action'];

  @Input() assessment: any;
  @Input() isEditable!: boolean;

  constructor(
    public dialog: MatDialog,
    private readonly reportService: ReportService,
    private readonly dialogService: DialogService,
    private readonly titleService: Title,
  ) { }

  ngOnInit(): void {
    this.dialogService.openSpinner()
    this.loadData()
  }

  loadData() {
    let reportRelations = '&relations=users,users.skills,users.user,users.user.skills,users.user.skills.skill,assessment,supporting_files,supports,soil_burn_severities,comments,forests,report_status';
    this.reportService.getReportList(this.assessment.assessment_id, reportRelations).subscribe(
      {
        next: (data) => {
          let reports = data.reports;
          reports.sort((reportA: any, reportB: any) => reportA.report_num - reportB.report_num)
          this.setTooltip(reports);
          this.reports.data = reports;
        },
        complete: () => {
          this.dialogService.closeSpinner()
        }
      });
  }

  setTooltip(reports: any) {
    reports.forEach((report: any) => {
      let tooltip = 'Team Lead(s)\n';
      for (let member of report.users.filter((member: any) => member.role_id === 0)) {
        if (member.user)
          tooltip += member.user.first_name + ' ' + member.user.last_name + '\n'
      }
      for (let member of report.users.filter((member: any) => member.role_id === 1)) {
        if (member.user)
          tooltip += member.user.first_name + ' ' + member.user.last_name + '\n'
      }
      tooltip += '\nTeam Member(s)\n'
      for (let member of report.users.filter((member: any) => member.role_id === 2)) {
        if (member.user)
          tooltip += member.user.first_name + ' ' + member.user.last_name + '\n'
      }
      for (let member of report.users.filter((member: any) => member.role_id === 3)) {
        if (member.user)
          tooltip += member.user.first_name + ' ' + member.user.last_name + '\n'
      }
      report.tooltip = tooltip;
      this.addSupportMessage(report);
    });
  }

  addSupportMessage(report: any) {
    if (report.supports?.length > 0) {
      let currentRequests = report.supports.filter((support: any) => {
        return !support.support_completed
      })
      if (currentRequests.length == 1) {
        let support = currentRequests[0]
        let skillsText = this.setSkillsText(support)
        let addDetailsText = ""
        if (support.additional_details) {
          addDetailsText = `\n\nAdditional Details:\n${support.additional_details}`
        }
        report.support_tooltip = `Interregional Support Request\n\nType of Support:\n${support.support_type} ${skillsText}${addDetailsText}`
      } else if (currentRequests.length > 1) {
        report.support_tooltip = `There are ${currentRequests.length} requests on this report. Visit the Teams tab to view the details.`
      }
    }
  }

  setSkillsText(support: any) {
    let skillsText = ""
    if (support.support_type == 'Team Member(s)' && support.support_skills != "") {
      let skills = support.support_skills.split(", ")
      if (skills.length == 1) {
        skillsText = support.support_skills
      } else if (skills.length == 2) {
        skillsText = skills.join(" and ")
      } else {
        skills[skills.length - 1] = "and " + skills[skills.length - 1]
        skillsText = skills.join(", ")
      }
      skillsText = "\n\nSkills:\n" + skillsText
    }
    return skillsText;
  }

  requestSupport(report: any): void {
    this.dialog.open(RequestSupportComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.assessment,
        report: report,
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.loadData()
      }
    })
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }
}