import { Pipe } from "@angular/core";

@Pipe({ name: "reportStatusBadge" })
export class ReportStatusBadgePipe {
    transform(id: number): string {
        switch (id) {
            case 0: return "IN PROGRESS"
            case 1: return "READY FOR SUBMISSION"
            case 2: return "SUBMITTED"
            case 3: return "IN REVIEW"
            case 4: return "SIGNATURE REQUESTED"
            case 5: return "READY FOR FUNDING RECOMMENDATIONS"
            case 6: return "REGIONAL FUNDS APPROVED"
            case 7: return "FUNDING APPROVED"
            case -10: return "NOT STARTED"
            default: return id.toString();
        }
    }
}