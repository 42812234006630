import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { FireTrackerComponent } from './routes/fire-tracker/fire-tracker.component';
import { DataManipulationComponent } from './routes/data-manipulation/data-manipulation.component';
import { TestingGuard } from './components/guards/testing.guard';
import { RosterComponent } from './routes/roster/roster.component';
import { UserComponent } from './routes/user/user.component';
import { LoginRedirectComponent } from "./routes/login-redirect/login-redirect.component";
import { authGuard } from "./components/guards/auth.guard";
import { AssignedGuard} from "./components/guards/assigned.guard"
import { LoginComponent } from "./routes/login/login.component";
import { AssessmentsComponent } from './routes/assessments/assessments.component';
import { FireOverviewComponent } from './routes/fire-overview/fire-overview.component';
import { Report25008Component } from './routes/report-2500-8/report-2500-8.component';


const routes: Routes = [
  {
    path: "",
    component: UserComponent,
    canActivate: [authGuard]
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [authGuard]
  },
  {
    path: "fire-tracker",
    component: FireTrackerComponent,
    canActivate: [authGuard, AssignedGuard]
  },
  {
    path: "assessments",
    component: AssessmentsComponent,
    canActivate: [authGuard, AssignedGuard]
  },
  {
    path: "roster",
    component: RosterComponent,
    canActivate: [authGuard, AssignedGuard]
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [authGuard]
  },
  {
    path: "fire-overview",
    component: FireOverviewComponent,
    canActivate: [authGuard]
  },
  {
    path: "2500-8-report",
    component: Report25008Component,
    canActivate: [authGuard]
  },
  {
    path: "data-manipulation",
    canActivate: [TestingGuard, authGuard],
    canLoad: [TestingGuard],
    component: DataManipulationComponent
  },
  {
    path: "login-redirect",
    component: LoginRedirectComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
