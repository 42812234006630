<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red">*</span>Indicates required field</div>
    <h2 class="form-header">1. Report Type</h2>

    <mat-card [formGroup]="form">
        <div class="title"><span class="red">*</span>Report Type
            <mat-icon [matTooltip]="'Learn more about each report type.'" matTooltipClass="tooltipClass"
                (click)="reportTypeModal($event)" onKeyUp="reportTypeModal($event)"
                (keyup.enter)="reportTypeModal($event)" focusable
                class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
        </div>
        <mat-radio-group required class="radio-buttons" formControlName="report_type">
            <mat-radio-button [value]="'Emergency Stabilization'" color="primary">Funding Request for Estimated
                Emergency Stabilization Funds</mat-radio-button>
            <br>
            <mat-radio-button [value]="'No Treatment'" color="primary">No Treatment
                Recommendation</mat-radio-button>
            <br>
            <mat-radio-button [value]="'Pre-assessment Request'" color="primary">Pre-assessment Funding
                Request</mat-radio-button>
        </mat-radio-group>
        <div class="title"><span class="red">*</span>Does this assessment cover the full fire area or a phased area?
            <mat-icon
                [matTooltip]="'Learn more about the difference between a full fire assessment and a phased assessment'"
                matTooltipClass="tooltipClass" (click)="fullVsPhasedModal($event)" onKeyUp="fullVsPhasedModal($event)"
                (keyup.enter)="fullVsPhasedModal($event)" focusable
                class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
        </div>
        <mat-radio-group required class="radio-buttons" formControlName="initial_report_type">
            <mat-radio-button [value]="'Full Fire Assessment'" color="primary">Full Fire Assessment</mat-radio-button>
            <br>
            <mat-radio-button [value]="'Phased Assessment'" color="primary">Phased Assessment</mat-radio-button>
        </mat-radio-group>
    </mat-card>
</main>