import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrl: './remove-user.component.scss'
})
export class RemoveUserComponent {
  userText: string;

  constructor(
    public dialogRef: MatDialogRef<RemoveUserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      role: string,
      users: any[]
    },
    private readonly userService: UserService,
    private readonly toast: ConfirmationToastComponent,
    private readonly dialogService: DialogService,
  ) {
    dialogRef.disableClose = true;
    this.userText = "Are you sure you want to remove "+this.data.users.map((user: any) => "<b>"+user.first_name+" "+user.last_name+"</b>").join(" and ")+"?";
  }

  removeUser(): void {
    this.dialogService.openSpinner()
    let promises: any[] = []
    this.data.users.forEach((user: any) => {
      let promise = new Promise((resolve, reject)=> {
        this.userService.editUserDetails({
          user_id: user.user_id,
          BAER_role_type: this.data.role,
          procedure: 'UNASSIGN'
        }).subscribe({
          next: (response) => {
            resolve(response)
          },
          error: (response) => {
            reject(new Error(response))
          },
        })
      })
      promises.push(promise)
    })
    Promise.all(promises).then((result) => {
      this.dialogService.closeSpinner()
      let userNames = this.data.users.map((user: { first_name: any; }) => user.first_name).join(" and ")
      if (this.data.users.length == 1) {
        userNames += " has"
      } else {
        userNames += " have"
      }
      this.toast.openSuccessSnackBar(`${userNames} been removed from the forest.`);
      this.dialogRef.close(true);
    }).catch((response) => {
      this.dialogService.closeSpinner()
      this.toast.openErrorSnackBar("There was an error removing the user", "Please try removing the user again.");
      this.dialogRef.close(false);
    })
    // this.userService.removeUser(this.data.role, {user_id_list: this.data.users.map((user: { user_id: any; }) => user.user_id)}).subscribe({
    //   next: (response) => {

    //   },
    //   error: (response) => {
    //   },
    // })
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
