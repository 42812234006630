import { Component, OnChanges, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportTypeModalComponent } from './report-type-modal/report-type-modal.component';
import { FullPhasedModalComponent } from './full-phased-modal/full-phased-modal.component';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { ReportService } from 'src/app/services/report.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-report-type',
  templateUrl: './report-type.component.html',
  styleUrls: ['./report-type.component.scss']
})


export class ReportTypeComponent implements OnChanges {

  report = input.required<any>();
  nav = input.required<string>();
  saveSubject = new Subject<any>();

  form = this.formBuilder.group({
    report_type: [""],
    initial_report_type: [""],
  });

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
    public readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {

    this.form.patchValue({
      report_type: this.report().report_type,
      initial_report_type: this.report().initial_report_type,
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 2 } });
        break;
      }
      case 'back': {
        //no back from this page
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
  }

  reportTypeModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(ReportTypeModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  fullVsPhasedModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(FullPhasedModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  save() {
    this.saveSubject.next(null)

    if (!this.form.dirty) {
      // Don't save if nothing has changed
      return
    }

    let allFieldsFilled = true

    for (let field in this.form.controls) {
      if (this.form.get(field)?.value === null || this.form.get(field)?.value === "") {
        allFieldsFilled = false
      }
    }


    this.reportService.updateReport({
      report_id: this.report().report_id,

      report_type: this.form.value.report_type as string | undefined,
      initial_report_type: this.form.value.initial_report_type as string | undefined,

      page_1_status_id: allFieldsFilled ? 2 : 1,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }
}
