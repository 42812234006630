<main id="main-content" class="main-content">
  <div class="subtitle" style="font-size: 14px;"><span class="red">*</span>Indicates required field</div>
  <h2 class="form-header">6. Emergency Stabilization Treatments and Requested Funds </h2>

  <mat-card [formGroup]="form">
    <div class="title">Total Summary
    </div>
    <div class="subtitle" style="font-weight: 700;"><span class="red">*</span>Assessment Cost</div>
    <p style="color: #727272; margin-left: 5px; margin-top: 10px; font-size: 16px;">This cost includes all forests in the report, and is
      <span style="font-weight: 700;">not included</span> in the Total Requested amount.
    </p>
    <mat-form-field appearance="outline" class="example small-field" style="max-width: 200px;">
      <input (change)="totalRequestedCalc()" class="right" id='assessmentCost' formControlName="assessment_cost" matInput aria-label="assessment_cost">
    </mat-form-field>
    <div class="subtitle" style="font-weight: 700;">Total Requested</div>
    <p style="margin-top: 10px;">{{totalRequested | currency}}</p>
  </mat-card>
  @for (forest of report().forests; track forest) {
  <mat-card>
    <div class="title">{{forest.forest_name}}</div>
    <table mat-table [dataSource]="forest.data" class="mat-elevation-z8">
      <!-- Type Column -->
      <ng-container matColumnDef="types">
        <th mat-header-cell *matHeaderCellDef>Types</th>
        <td mat-cell *matCellDef="let item"> {{item.type ?? "--"}} </td>
        <td mat-footer-cell *matFooterCellDef colspan="9">Current Requested Total</td>
      </ng-container>
      <!-- Treatment Column -->
      <ng-container matColumnDef="treatment">
        <th mat-header-cell *matHeaderCellDef>Treatment</th>
        <td mat-cell *matCellDef="let item"> {{item.treatment ?? "--"}} </td>
      </ng-container>
      <!-- Spec. # Column -->
      <ng-container matColumnDef="spec_number">
        <th mat-header-cell *matHeaderCellDef>Spec. #</th>
        <td mat-cell *matCellDef="let item"> {{item.spec_number}} </td>
      </ng-container>
      <!-- Spec. Name Column -->
      <ng-container matColumnDef="spec_name">
        <th mat-header-cell *matHeaderCellDef>Spec. Name</th>
        <td mat-cell *matCellDef="let item"> {{item.spec_name ?? "--"}} </td>
      </ng-container>
      <!-- Off-NFS Column -->
      <ng-container matColumnDef="off_nfs">
        <th mat-header-cell *matHeaderCellDef>Off-NFS</th>
        <td mat-cell *matCellDef="let item"> {{item.off_nfs ? "Yes": (item.off_nfs === null? "--":"No")}} </td>
      </ng-container>
      <!-- Outside Fire Column -->
      <ng-container matColumnDef="outside_fire">
        <th mat-header-cell *matHeaderCellDef>Outside Fire</th>
        <td mat-cell *matCellDef="let item"> {{item.outside_fire ? "Yes": (item.outside_fire === null? "--":"No")}} </td>
      </ng-container>
      <!-- Unit Type Column -->
      <ng-container matColumnDef="unit_type">
        <th mat-header-cell *matHeaderCellDef>Unit Type</th>
        <td mat-cell *matCellDef="let item"> {{item.unit_type ?? "--"}} </td>
      </ng-container>
      <!-- num of units Column -->
      <ng-container matColumnDef="unit_qty">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;"># of Units</th>
        <td mat-cell *matCellDef="let item" style="text-align: end;"> {{item.unit_qty}} </td>
      </ng-container>
      <!-- Unit Cost Column -->
      <ng-container matColumnDef="unit_cost">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">Unit Cost</th>
        <td mat-cell *matCellDef="let item" style="text-align: end;"> {{item.unit_cost | currency}} </td>
      </ng-container>

      <!-- Cost Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">Total ($)</th>
        <td mat-cell *matCellDef="let item" style="text-align: end;"> {{unitTotal(item) | currency}} </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: end;"> {{forestTotal(forest) | currency}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="footerDisplayedColumns"></tr>

      
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="13">
            <div class="no-data">
                <div class="no-data-icon">
                    <mat-icon class="no-data-icon1">search</mat-icon>
                    <mat-icon class="no-data-icon2">attach_money</mat-icon>
                </div>
                <div class="no-data-title">Requested Funds Unavailable</div>
                <p style="max-width: 370px; text-align: center;">No treatment funds have been requested yet in the Recommended Treatment Narratives section</p>
            </div>
          </td>
        </tr>
    </table>
  </mat-card>
  }
</main>