import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faTrash, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Editor, Toolbar } from 'ngx-editor';
import { ReportService } from 'src/app/services/report.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportForest } from 'src/app/models/reportForest.model';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-select-line-officer',
  templateUrl: './select-line-officer.component.html',
  styleUrls: ['./select-line-officer.component.scss']
})


export class SelectLineOfficerComponent implements OnChanges, OnInit {

  faUsers = faUsers;
  faTrash = faTrash;
  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;
  filtered_roles: any[] = [];

  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  editors: Editor[] = [];

  report = input.required<any>();
  nav = input.required<string>();
  is_co = input.required<boolean>();
  forests: any[] = []
  lastCheckedEmail: any = "";
  roles: any;


  displayedColumns: string[] = ["name", "position", "forest"];
  users = new MatTableDataSource<any>();
  selectUserId: number | null = null;

  fireForm = this.formBuilder.group({
    radio: [""],
  });

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
    public readonly userService: UserService,
    public readonly dialogService: DialogService,
    public readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
    this.userService.getRoleList().subscribe((roles) => {
      this.roles = roles
      this.filtered_roles = this.roles.filter((r: any) => r.type.slice(-2) == "LO");
    });
  }

  ngOnInit(): void {
    this.loadUsers();
    this.forests = this.report().forests.map((forest: ReportForest) => {
      let newForest = forest as any;
      newForest.form = this.formBuilder.group({
        name_search: [""],
        new_or_existing: [forest.line_officer ? "existing" : ""],
        user_select: [""],
        first_name: [""],
        last_name: [""],
        email: [""],
        phone_num: [""],
        lo_position: [""]
      })
      newForest.selectUserForm = this.formBuilder.group({
        user: [forest.line_officer || ""],
      })
      newForest.userExists = false;
      newForest.contact = null;
      newForest.users = new MatTableDataSource<any>();
      return newForest;
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        if (this.is_co()) {
          this.reportService.updateReport({
            report_id: this.report().report_id,
            report_status_id: 1 //READY FOR SUBMISSION
          }).subscribe((x: any) => {
            this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 9 } });
          });
        } else {
          this.reportService.updateReport({
            report_id: this.report().report_id,
            report_status_id: 3 //in review
          }).subscribe((x: any) => {
            this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 9 } });
          });
        }
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 7 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    let forests = this.forests.map((forest: any) => {
      return {
        forest_id: forest.forest_id,
        line_officer_id: forest.selectUserForm.value.user?.user_id
      }
    })
    this.update()

    this.reportService.updateReport({
      report_id: this.report().report_id,
      forests: forests,
      page_8_status_id: this.report().page_8_status_id,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
  }


  loadUsers() {
    this.userService.getUserList("LO", ["baer_roles.baer_role"]).subscribe(data => {
      console.log("users",data.users)
      let users = data.users
      users.forEach((user: any) => {
        user.baer_roles = user.baer_roles.filter((role: any) => {
          return role.baer_role.type.slice(-2) == "LO"
        })
        user.tooltip = user.baer_roles.map((role: any) => role.baer_role.text).join("\n")
      })
      this.forests.forEach((forest: any) => {
        let LOs = users.filter((user: any) => user.forest_name == forest.forest_name)
        forest.users.data = LOs
        
        if (this.selectUserId) {
          let user = LOs.find((user: any) => user.user_id == this.selectUserId)
          if (user) {
            console.log("somehting",LOs.find((user: any) => user.user_id == this.selectUserId))
            forest.selectUserForm.patchValue({
              user: user,
            })
            forest.form.patchValue({
              new_or_existing: 'existing',
            })
          }
          this.update()
        }
        if (forest.selectUserForm.value.user) {
          forest.selectUserForm.patchValue({
            user: LOs.find((user: any) => user.user_id == forest.selectUserForm.value.user.user_id)
          })
        }
      })
      this.users.data = users
      this.dialogService.closeSpinner()
    });
  }

  radioChange(event: any, forest: any) {
    this.update()
  }

  checkIfUserExists(forest: any): void {
    if (forest.form.controls.email.errors?.pattern) {
      forest.form.controls.email.setErrors({ "pattern": forest.form.controls.email.errors.pattern })
      return
    }
    let email = forest.form.value.email
    if (this.lastCheckedEmail == email) return // Prevent double event firing
    this.lastCheckedEmail = email
    if (email == "") {
      forest.form.controls.email.setErrors(null)
      return
    }
    this.dialogService.openSpinner();
    this.userService.getUsersByEmail(email).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner();
        if (response.totalResults > 0) {
          forest.form.controls.email.setErrors({ "TeamMember": true })
        } else {
          forest.form.controls.email.setErrors(null)
        }
      },
      error: (response) => {
        forest.userExists = false;
        this.dialogService.closeSpinner();
      },
      complete: () => {
        this.update()
      }
    })
  }

  update() {
    let touched = false
    let complete = true

    this.forests.forEach((forest: any) => {
      if (forest.selectUserForm.value.user) {
        touched = true
      } else {
        complete = false
      }
    })

    if (touched) {
      this.report().page_8_status_id = 1
    }
    if (complete) {
      this.report().page_8_status_id = 2
    }
  }

  addLineOfficer(forest: any) {
    this.dialogService.openSpinner()
    this.userService.createNewUser({
      first_name: forest.form.value.first_name!,
      last_name: forest.form.value.last_name!,
      region: forest.region,
      forest_name: forest.forest_name,
      phone_num: forest.form.value.phone_num || undefined,
      email: forest.form.getRawValue().email!,
      user_baer_roles: [{ baer_role_id: forest.form.value.lo_position.value }],
    }).subscribe((response: any) => {
      if (response.user_id) {
        this.selectUserId = response.user_id
        this.loadUsers()
      }
    })
  }
}
