import { Component, OnInit, input, model } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-skills',
  templateUrl: './edit-skills.component.html',
  styleUrl: './edit-skills.component.scss'
})
export class EditSkillsComponent implements OnInit {
  edit_skills = model<boolean>();
  user = input<any>();
  refreshUser = input<Subject<void>>();
  exp_levels: any;

  skillsForm = this.formBuilder.group({
    'Team Leader': ["-1"],
    'Assistant Team Leader': ["-1"],
    'Logistics Coordinator': ["-1"],
    'Liaison Officer': ["-1"],
    'Archaeology': ["-1"],
    'Engineer': ["-1"],
    'Fisheries': ["-1"],
    'Geology': ["-1"],
    'GIS': ["-1"],
    'Hydrologist': ["-1"],
    'Interagency Coordinator': ["-1"],
    'Invasive Species/Botany': ["-1"],
    'Public Information Officer': ["-1"],
    'Recreation Specialist': ["-1"],
    'Safety Officer': ["-1"],
    'Soil Scientist': ["-1"],
    'Wildlife Biologist': ["-1"],
    skills_text: [""],
    large_fire_exp: [false],
    red_card: [false],
    line_gear: [false],
    red_card_qualifications: [""],
    team_references: [""],
    skills_add_comments: [""]
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly toast: ConfirmationToastComponent,
  ) { 
    this.exp_levels = this.userService.getExpList();

  }

  ngOnInit(): void {
    let skillIdMap = this.userService.getSkillIdMap()
    
    let values: { [id: string] : any} = {};
    this.user().skills.forEach((skillObject: any) => {
      values[skillIdMap.get(skillObject.skill_id)!] = skillObject.experience_id;
    })
    this.skillsForm.patchValue(values) // Patch skill values from user object
    this.skillsForm.patchValue({
      skills_text: this.user().skills_text,
      large_fire_exp: this.user().large_fire_exp,
      red_card: this.user().red_card,
      line_gear: this.user().line_gear,
      red_card_qualifications: this.user().red_card_qualifications,
      team_references: this.user().team_references,
      skills_add_comments: this.user().skills_add_comments,
    })
  }

  save() {
    let skills = []
    let skillNames = this.userService.getSkillNames()
    
    // Check each item in skillForm for a value to save to skills
    for (let skill in this.skillsForm.value) {
      if (skill in skillNames) {
        if (this.skillsForm.get(skill)!.value != -1) {
          skills.push({
            skill_id: skillNames[skill],
            experience_id: this.skillsForm.get(skill)!.value
          })
        }
      }
    }

    this.userService.editUserDetails({
      user_id: this.user().user_id,
      skills_list: skills,
      skills_text: this.skillsForm.value.skills_text,
      red_card_qualifications: this.skillsForm.value.red_card_qualifications,
      large_fire_exp: this.skillsForm.value.large_fire_exp,
      red_card: this.skillsForm.value.red_card,
      line_gear: this.skillsForm.value.line_gear,
      team_references: this.skillsForm.value.team_references,
      skills_add_comments: this.skillsForm.value.skills_add_comments,
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`User's skills were successfully updated.`);
        this.edit_skills.update((x) => {
          return false;
        });
        this.refreshUser()!.next();
      },
      error: (response) => {
        this.toast.openErrorSnackBar("There was an error updating the user.", "Please try again.");
      },
    })
  }

  cancel() {
    this.edit_skills.update((x) => {
      return false;
    });
  }
}
