import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-remove-member',
  templateUrl: './remove-member.component.html',
  styleUrl: './remove-member.component.scss'
})
export class RemoveMemberComponent {
  faUserSlash = faUserSlash;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly titleService: Title,
  ) {
    this.titleService.setTitle("BAER - Remove Member")
  }
}
