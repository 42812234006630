<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">8. Select Forest Unit Line Officer(s)</h2>
    <div class="subtitle-big">
        Select the forest unit line officer(s) contact(s) that will review and certify this 2500-8 report. If you can’t
        find the appropriate contact(s), select the <b>‘Add new forest unit line officer’</b> option.
    </div>

    <div *ngFor="let forest of forests; let i = index" class="forest-container">
        <h2 class="forest-title">{{ forest.name }}</h2>
        <mat-card [formGroup]="forest.form">
            <div class="title"><span class="red">*</span>Select an option:
            </div>
            <mat-radio-group required class="radio-buttons" formControlName="new_or_existing" (input)="update">
                <mat-radio-button [value]="'existing'" color="primary">Search for an existing forest unit line officer
                    (preferred)</mat-radio-button>
                <br>

                <div *ngIf="forest.form.value.new_or_existing == 'existing'" class="side-line">

                    <div class="table-container">
                        <table mat-table [dataSource]="forest.users" class="center" matSort matSortDisableClear
                            aria-hidden="false">
                            <form [formGroup]="forest.selectUserForm">
                                <mat-radio-group formControlName="user">
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

                                        <td class="user-cell" mat-cell *matCellDef="let user"
                                            [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                                            <div style="display:flex">
                                                <mat-radio-button [value]="user" color="primary" class="radio-button"
                                                    (change)="radioChange($event, forest)"></mat-radio-button>
                                                <div class="user-cell-name">
                                                    <div class="user-icon vertical-center">
                                                        <p>{{user.first_name[0]}}</p>
                                                    </div>
                                                    <p class="user-name-2">{{user.first_name + " " + user.last_name}}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                </mat-radio-group>
                            </form>
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>Position</th>
                                <td mat-cell *matCellDef="let user">
                                    <mat-chip class="chip"
                                        *ngIf="user.baer_roles.length != 0; else roleDef">{{user.baer_roles[0].baer_role.text}}</mat-chip>
                                    <ng-template #roleDef>--</ng-template>
                                    <mat-chip class="chip" *ngIf="user.baer_roles.length > 1" style="margin-left: 5px;"
                                        [matTooltip]='user.tooltip' matTooltipClass="tooltipClass">
                                        +{{user.baer_roles.length - 1}}
                                    </mat-chip>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="forest">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 280px;">Forest name</th>
                                <td mat-cell *matCellDef="let user">
                                    {{ user.forest_name }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true" class="header-row"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">
                                    <div class="no-data">
                                        <div class="no-data-icon">
                                            <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
                                        </div>
                                        <div class="no-data-title">No team members found</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <mat-radio-button [value]="'new'" color="primary">Add new forest unit line officer</mat-radio-button>
                <div *ngIf="forest.form.value.new_or_existing == 'new'" class="side-line">
                    <form class="user_form" [formGroup]="forest.form">
                        <mat-form-field appearance="outline"> 
                            <mat-label>First Name</mat-label>
                            <input matInput formControlName="first_name" required cdkFocusInitial (input)="update">
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="last_name" required cdkFocusInitial (input)="update">
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Email Address</mat-label>
                            <input matInput formControlName="email" required
                                pattern="[a-zA-Z0-9._%+\-]+&#64;[a-z0-9.\-]+\.[a-z]{2,3}$" cdkFocusInitial
                                (focusout)="checkIfUserExists(forest)" (keyup.enter)="checkIfUserExists(forest)">
                            <mat-error *ngIf="forest.form.value.email == ''">Required Field</mat-error>
                            <mat-error *ngIf="forest.form.controls.email.errors?.pattern">Please enter a valid email</mat-error>
                            <mat-error *ngIf="forest.form.controls.email.errors?.TeamMember">Team member already exists</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input matInput formControlName="phone_num" cdkFocusInitial placeholder="(XXX) XXX-XXXX"
                                mask="(000) 000-0000">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Line Officer Position</mat-label>
                            <mat-select formControlName="lo_position" required (input)="update">
                                @for (role of filtered_roles; track role) {
                                <mat-option [value]="role">{{role.text}}</mat-option>
                                }
                            </mat-select>
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                        <div class="bonus-field">
                            <div>
                                <b>Region</b>
                                <br>
                                {{ forest.region }}
                                <!-- Add region here of forest -->
                            </div>
                            <div style="width: 75%">
                                <b>Forest</b>
                                <br>
                                {{ forest.forest_name }}
                            </div>
                        </div>
                    </form>
                    <button mat-flat-button [class]="forest.form.invalid? '':'add-user' " color="primary" (click)="addLineOfficer(forest)" [disabled]="forest.form.invalid">Add Line Officer</button>
                </div>
            </mat-radio-group>
            <div class="divide-line"></div>
            <div class="title">
                The 2500-8 report will be sent to the following contact for certification:
            </div>
            <div class="contact-container">
                <mat-card *ngIf="forest.form.value.new_or_existing == 'new' && (forest.form.value.first_name && forest.form.value.last_name && forest.form.value.email && forest.form.value.lo_position)" class="contact-card">
                    <div>
                        <div class="user-cell-name">
                            <div class="user-icon">
                                <p>{{(forest.form.value.first_name || "?")[0]}}</p>
                            </div>
                            <p class="user-name-basic" *ngIf="forest.form.value.first_name || forest.form.value.last_name">{{forest.form.value.first_name + " " + forest.form.value.last_name}}</p>
                            <p class="user-name-basic" *ngIf="!forest.form.value.first_name && !forest.form.value.last_name">New User</p>
                        </div>
                        <div class="user-cell-details">
                            <mat-chip *ngIf="forest.form.value.lo_position">{{ forest.form.value.lo_position.text }}</mat-chip>
                            <div class="details-number" *ngIf="forest.form.value.phone_num"><mat-icon>phone</mat-icon> {{( forest.form.value.phone_num | phone) ||
                                "--"}}
                            </div>
                            <div class="details-email" *ngIf="forest.form.value.email"><mat-icon>email</mat-icon>{{forest.form.value.email}}</div>
                            <div class="details-email"><mat-icon>location_on</mat-icon>{{ forest.forest_name }}</div>
                        </div>
                    </div>
                </mat-card>
                <div *ngIf="forest.selectUserForm.value.user==null && (forest.form.value.new_or_existing != 'new' 
                || !(forest.form.value.first_name && forest.form.value.last_name && forest.form.value.email && forest.form.value.lo_position))" class="text-div">
                    <span class="gray-text">No contact selected</span>
                </div>
            </div>
            <mat-card *ngIf="forest.selectUserForm.value.user && forest.form.value.new_or_existing == 'existing'" class="contact-card">
                <div>
                    <div class="user-cell-name">
                        <div class="user-icon">
                            <p>{{forest.selectUserForm.value.user.first_name[0]}}</p>
                        </div>
                        <a [routerLink]="['/user']" [queryParams]="{user_id: forest.selectUserForm.value.user?.user_id}"
                            class="user-name">{{forest.selectUserForm.value.user.first_name + " " + forest.selectUserForm.value.user.last_name}}</a>
                    </div>
                    <div class="user-cell-details">
                        <mat-chip *ngFor="let role of forest.selectUserForm.value.user.baer_roles">{{ role.baer_role.text }}</mat-chip>
                        <div class="details-number"><mat-icon>phone</mat-icon> {{(forest.selectUserForm.value.user.phone_num | phone) ||
                            "--"}}
                        </div>
                        <div class="details-email"><mat-icon>email</mat-icon>{{forest.selectUserForm.value.user.email}}</div>
                        <div class="details-email"><mat-icon>location_on</mat-icon>{{ forest.selectUserForm.value.user.forest_name }}</div>
                    </div>
                </div>
            </mat-card>
        </mat-card>
    </div>



</main>