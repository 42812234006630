import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/services/report.service';
import Inputmask from "inputmask";
import { ReportForest } from 'src/app/models/reportForest.model';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { ForestTreament } from 'src/app/models/forestTreatment.model';
import { TreamentSpecification } from 'src/app/models/forestSpecification.model';

@Component({
  selector: 'app-emergency-stabilization',
  templateUrl: './emergency-stabilization.component.html',
  styleUrls: ['./emergency-stabilization.component.scss']
})


export class EmergencyStabilizationComponent implements OnChanges, OnInit {

  report = input.required<any>();
  nav = input.required<string>();


  form = this.formBuilder.group({
    assessment_cost: [0],
  });
  displayedColumns = [
    'types',
    'treatment',
    'spec_number',
    'spec_name',
    'off_nfs',
    'outside_fire',
    'unit_type',
    'unit_qty',
    'unit_cost',
    'total'
  ]
  footerDisplayedColumns = [
    'types',
    'total'
  ]
  forestTreatments: any;
  totalRequested = 0;

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
    public readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit() {
    let assessmentCost = document.getElementById("assessmentCost") ?? '';
    let im = new Inputmask("currency", {
      groupSeparator: ',',
      prefix: '$',
      allowMinus: false
    });
    im.mask(assessmentCost);
    this.form.patchValue({
      assessment_cost: this.report().assessment_cost,
    })
    this.report().forests.forEach((forest: ReportForest & {data: any[]}) => {
      forest.data = []
      forest.treatments!.forEach((treatment: ForestTreament) => {
        treatment.treatment_specifications!.forEach((spec: TreamentSpecification) => {
          forest.data.push({
            type: treatment.treatment_type?.treatment_type,
            treatment: treatment.treatment_option?.treatment_option,
            spec_number: spec.specification_number,
            spec_name: spec.specification_name,
            off_nfs: spec.treatment_off_nfs_land,
            outside_fire: spec.treatment_outside_fire,
            unit_type: treatment.treatment_option?.unit_of_measure,
            unit_qty: spec.custom_unit_qty ?? spec.gis_unit_qty,
            unit_cost: spec.unit_cost,
          })
        })
      })
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
    this.reportService.getReportForestTreatments(this.report().report_id).then((res: any) => {
      this.forestTreatments = res;
      this.totalRequestedCalc();
    })
  }

  unitTotal(item: any) {
    return item.unit_cost * item.unit_qty;
  }

  forestTotal(forest: ReportForest & {data: any[]}) {
    let total = 0;
    for (let item of forest.data) {
      total += this.unitTotal(item);
    }
    return total;
  }

  totalRequestedCalc() {
    let total = 0;
    for (let forest of this.report().forests) {
      total += this.forestTotal(forest);
    }
    this.totalRequested = total;
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 7 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 5 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    if (!this.form.dirty) {
      // Don't save if nothing has changed
      return
    }

    // Convert blank number fields to undefined
    let numbers = ["assessment_cost"]
    for (let field of numbers) {
      if (this.form.get(field)?.value === "") {
        this.form.patchValue({ [field]: null })
      } else if (this.form.get(field)?.value != null) {
        this.form.patchValue({ [field]: +this.form.get(field)?.value })
      }
    }

    let allFieldsFilled = true

    for (let field in this.form.controls) {
      if (this.form.get(field)?.value === null || this.form.get(field)?.value === "") {
        allFieldsFilled = false
      }
    }

    this.reportService.updateReport({
      report_id: this.report().report_id,

      assessment_cost: this.form.value.assessment_cost as string | undefined,

      page_6_status_id: allFieldsFilled ? 2 : 1,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
  }
}
