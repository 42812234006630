import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class TestingGuard {

    canActivate() {
        return environment.showTesting
    }
    canLoad() {
        return environment.showTesting
    }
}
