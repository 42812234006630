import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnChanges, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { faEdit, faTrash, faCommentSlash, faMessage } from '@fortawesome/free-solid-svg-icons';
import { ReportService } from 'src/app/services/report.service';
import { NewCommentComponent } from './new-comment/new-comment.component';
import { UserService } from 'src/app/services/user.service';
import { DeleteCommentComponent } from './delete-comment/delete-comment.component';

@Component({
  selector: 'app-report-2500-8',
  templateUrl: './report-2500-8.component.html',
  styleUrls: ['./report-2500-8.component.scss'],
  animations: [
    trigger('expand', [
      state('false', style({ width: AUTO_STYLE })),
      state('true', style({ width: '330px' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})


export class Report25008Component implements OnChanges {
  //icons
  faEdit = faEdit;
  faTrash = faTrash;
  faCommentSlash = faCommentSlash;
  faMessage = faMessage;

  report_id = input.required<number>();
  report_page_input = input<string>();
  report_page: number = 0;

  report: any;
  report_status: any;
  nav: string = '';
  comment_expand: boolean = false;
  comments: any;
  comments_unresolved: boolean = false;
  reportSections: any;

  currentUser: any;
  is_co: boolean = false;

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly reportService: ReportService,
    private readonly userService: UserService
  ) {
    this.titleService.setTitle("BAER - Report Type");
    this.reportSections = this.reportService.getReportPageList();
  }

  ngOnChanges(): void {
    let relations: string[] = []
    switch (this.report_page_input()) {
      case "1":
        relations = ["assessment"]
        break
      case "2":
        relations = ["assessment", "stateCounties", "supporting_files", "watersheds", "forests","soil_burn_severities"]
        break
      case "3":
        relations = ["assessment", "stateCounties", "supporting_files", "watersheds", "soil_burn_severities"]
        break
      case "4":
        relations = ["assessment", "supporting_files"]
        break
      case "5":
        relations = ["assessment", "forests", "forests.treatments", "forests.treatments.treatment_specifications"]
        break
      case "6":
        relations = ["assessment", "forests", "forests.treatments", "forests.treatments.treatment_specifications", "forests.treatments.treatment_option", "forests.treatments.treatment_type"]
        break
      case "7":
        relations = ["assessment"]
        break
      case "8":
        relations = ["assessment", "forests", "forests.line_officer"]
        break
      default:
        relations = ["assessment"]
    }
    this.reportService.getReport(this.report_id(), relations).subscribe((report: any) => {
      this.report = report.reports[0];
      this.setReportComments();
      this.setReportStatus();
      this.nav = '';
    });
    this.userService.getUserProfile().then((user) => {
      this.currentUser = user;
      // 3 - BAER Regional Coordinator
      // 12 - RO BAER Coordinator
      // 13 - RO BAR Coordinator
      // 15 - RO Disaster Coordinator
      const is_co = this.currentUser.baer_roles.find((obj: any) => [0, 3, 4, 5, 7, 8, 9, 11, 12, 13, 15, 16, 17].includes(obj.value))
      if (is_co) this.is_co = true;
    })
  }

  save_close() {
    this.nav = 'close'
  }

  forward() {
    this.nav = 'forward'
  }

  back() {
    this.nav = 'back'
  }

  setReportComments() {
    this.reportService.getReportComments(this.report_id()).subscribe((reportComments: any) => {
      this.comments = reportComments.reportCommentEntities;
      let findUnresolved = this.comments.find((x: any) => !x.resolved)
      if (findUnresolved ?? false) this.comments_unresolved = true;
    })
  }

  setReportStatus() {
    //TODO
    //true - complete
    //false - incomplete
    this.report_status = [
      { report_page: 1, status: false },
      { report_page: 2, status: false },
      { report_page: 3, status: false },
      { report_page: 4, status: false },
      { report_page: 5, status: false },
      { report_page: 6, status: false },
      { report_page: 7, status: false },
      { report_page: 8, status: false }
    ]
    this.setPage();
  }

  setPage() {
    //if no page passed in, set page to latest page not finished
    if (!this.report_page_input()) {
      const filtered_status = this.report_status.filter((status: any) => !(status.status))
      const lowestItem = filtered_status.reduce((prev: any, curr: any) => (prev.report_page < curr.report_page ? prev : curr));
      this.report_page = lowestItem.report_page;
    }
    else {
      this.report_page = (parseInt(this.report_page_input() ?? '') ?? 0);
    }
  }

  addComment() {
    this.dialog.open(NewCommentComponent, {
      width: "600px",
      data: {
        'report_num': this.report_page,
        report_id: this.report_id(),
        edit: false,
        user: this.currentUser
      },
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.setReportComments();
      }
    })
  }

  editComment(comment: any) {
    this.dialog.open(NewCommentComponent, {
      width: "600px",
      data: {
        'report_num': comment.section,
        report_id: this.report_id(),
        comment: comment,
        edit: true,
        user: this.currentUser
      },
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.setReportComments();
      }
    })
  }

  deleteComment(comment: any) {
    this.dialog.open(DeleteCommentComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        comment_id: comment.report_comment_id
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.setReportComments();
      }
    })
  }

  findPage(pageNum: any) {
    return this.reportSections.find((x: any) => x.pageNum == pageNum).pageName;
  }
}
