<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>
            <mat-icon class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
            BAER Assessment Complexity Levels</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <p>
            The following BAER Assessment Complexity levels are to be used during the initial BAER incident size up: Low, Moderate, and High.
        </p>
        <p>
            The complexity assigned to the BAER incident will be used to inform Regional and National Coordinators of potential team staffing needs,
             interagency coordination needs, known threats to FS and Non-FS BAER critical values (BCVs), 
             and potential Public Affairs issues that could occur during in the post-fire response. 
             The incident does not have to include each factor listed in the complexity definition.
        </p>
        <p><b>Low</b></p>
        <ul>
            <li>
                Threats to BCVs are limited in scope to FS owned assets and FS managed natural and cultural resources.
            </li>
            <li>
                Impacts to off-forest resources are not anticipated.
            </li>
            <li>
                Limited need for interagency coordination.
            </li>
            <li>
                Media coverage is expected to be limited to local newspapers and radio.
            </li>
        </ul>
        <p><b>Moderate</b></p>
        <ul>
            <li>
                Fire size has exceeded 20,000 acres.
            </li>
            <li>
                Large, complex FS transportation systems are threatened by post-fire events.
            </li>
            <li>
                Threats to off-forest and downstream private values are expected. 
            </li>
            <li>
                Interagency coordination will be needed during the BAER assessment.
            </li>
        </ul>
        <p><b>High</b></p>
        <ul>
            <li>
                Fire size has exceeded 100,000 acres
            </li>
            <li>
                National level media and political attention to the FS post-fire response is expected.
            </li>
            <li>
                Land ownership within the burn scar is very diverse with numerous entities affected.
            </li>
            <li>
                Threats to major utilities, interstate transportation infrastructure, and interstate commerce are present.
            </li>
            <li>
                Large populations centers may be affected by flooding and/or debris flows.
            </li>
            <li>
                Inter-regional BAER team staffing with highly experienced personnel is needed, including interagency coordination and public information.
            </li>
        </ul>
    </mat-dialog-content>
</div>