<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">7. Team & Supporting Documents</h2>

    <mat-card [formGroup]="form" style="margin-bottom: 24px;">
        <div class="title">Supporting Documents</div>
        <div class="text-div">
            <b><span class="red">*</span>Supporting Documents</b>
            <span class="gray-text"> Enter the file's Box link</span>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <input matInput formControlName="supporting_documents" aria-label="Supporting Documents"
                    placeholder="Enter FS Box url e.g. www.box.com/">
                <mat-error>Please enter a valid URL.</mat-error>
            </mat-form-field>
        </div>
        <div class="text-div">
            <b><span class="red">*</span>Treatment Recommendations Map</b>
            <span class="gray-text"> Enter the file's Box link</span>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <input matInput formControlName="treatment_recommendations" aria-label="Treatment Recommendations map"
                    placeholder="Enter FS Box url e.g. www.box.com/">
                <mat-error>Please enter a valid URL.</mat-error>
            </mat-form-field>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Soil Burn Severity Map</b>
            <span class="gray-text"> Enter the file's Box link</span>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <input matInput formControlName="burn_severity" aria-label="Soil burn severity map"
                    placeholder="Enter FS Box url e.g. www.box.com/">
                <mat-error>Please enter a valid URL.</mat-error>
            </mat-form-field>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>KMZ Submittal Package</b>
            <span class="gray-text"> Enter the file's Box link</span>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <input matInput formControlName="kmz_submittal_package" aria-label="KMZ Submittal package"
                    placeholder="Enter FS Box url e.g. www.box.com/">
                <mat-error>Please enter a valid URL.</mat-error>
            </mat-form-field>
        </div>
        <div class="divide-line"></div>
        <div class="text-div" style="position: relative;">
            <b>Other links (optional)</b>
            <br>
            <span class="gray-text">Add additional file links to provide direct link in the report</span>
            <button class="usa-button usa-button--secondary medium-length add-link-btn" (click)="addFileLink()">
                <mat-icon>add</mat-icon>Add File Link
            </button>
        </div>
        <div class="table-container">
            <table>
                <tr class="table-header">
                    <th>File name</th>
                    <th>Box Link</th>
                    <th></th>
                </tr>
                <ng-container formArrayName="otherLinks">
                    <ng-container *ngFor="let data of otherLinks.controls; let i = index">
                        <tr [formGroupName]="i" class="table-row">
                            <td style="width: 20%">
                                <mat-form-field appearance="outline" class="example">
                                    <input placeholder="Enter file name" formControlName="file_name" matInput
                                        aria-label="File Name">
                                </mat-form-field>
                            </td>
                            <td style="width: 70%">
                                <mat-form-field appearance="outline" class="example">
                                    <input placeholder="Enter FS Box url e.g. www.box.com/" formControlName="link"
                                        matInput aria-label="link">
                                    <mat-error>Please enter a valid URL.</mat-error>
                                </mat-form-field>
                            </td>
                            <td>
                            <td class="close-td right">
                                <div class="delete-div" matTooltip='Remove' matTooltipClass="tooltipClass">
                                    <fa-icon (click)="removeFileLink(i)" onKeyDown="removeFileLink(i)" tabindex="0"
                                        [icon]="faCircleXmark" class="fa-regular fa-circle-mark delete-row-icon"
                                        aria-label="close"></fa-icon>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </div>
    </mat-card>
    <mat-card>
        <div class="title">Team Roster</div>
        <div>
            <div class="text-div">
                <b>Team Leads</b>
            </div>
            <div class="contact-container">
                <div *ngFor="let user of users.data">
                    <mat-card *ngIf="user.role.role_id == 0 || user.role.role_id == 1" class="contact-card">
                        <div>
                            <div class="user-cell-name">
                                <div class="user-icon">
                                    <p>{{user.user.first_name[0]}}</p>
                                </div>
                                <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user.user_id}"
                                    class="user-name">{{user.user.first_name + " " + user.user.last_name}}</a>
                            </div>
                            <div class="user-cell-details">
                                <mat-chip>{{ user.role.role }}</mat-chip>
                                <div class="details-number"><mat-icon>phone</mat-icon> {{(user.user.phone_num | phone)
                                    ||
                                    "--"}}
                                </div>
                                <div class="details-email"><mat-icon>email</mat-icon>{{user.user.email}}</div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div *ngIf="forestContacts.length > 0">
            <div class="text-div">
                <b>Forest Coordinators</b>
            </div>
            <div class="contact-container">
                <mat-card *ngFor="let user of forestContacts" class="contact-card">
                    <div>
                        <div class="user-cell-name">
                            <div class="user-icon">
                                <p>{{user.first_name[0]}}</p>
                            </div>
                            <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user_id}"
                                class="user-name">{{user.first_name + " " + user.last_name}}</a>
                        </div>
                        <div class="user-cell-details">
                            <mat-chip *ngFor="let role of user.showRoles">{{ role }}</mat-chip>
                            <div class="details-number"><mat-icon>phone</mat-icon> {{(user.phone_num | phone) ||
                                "--"}}
                            </div>
                            <div class="details-email"><mat-icon>email</mat-icon>{{user.email}}</div>
                            <div class="details-email"><mat-icon>location_on</mat-icon>{{ user.forest_name }}</div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>

        <div class="text-div">
            <b>All Team Members and Skills</b>
        </div>
        <table mat-table [dataSource]="users" class="center" matSort matSortDisableClear aria-hidden="false">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

                <td class="user-cell" mat-cell *matCellDef="let user">
                    <div>
                        <div class="user-cell-name">
                            <div class="user-icon">
                                <p>{{user.user.first_name[0]}}</p>
                            </div>
                            <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user.user_id}"
                                class="user-name">{{user.user.first_name + " " + user.user.last_name}}</a>
                        </div>
                        <div class="user-cell-details">
                            <div class="details-email"><mat-icon>email</mat-icon>{{user.user.email}}</div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                    <mat-chip class="chip" *ngIf="user.role; else roleDef">{{user.role.role}}</mat-chip>
                    <ng-template #roleDef>--</ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="skills">
                <th mat-header-cell *matHeaderCellDef>Skills</th>
                <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                    <div *ngIf="user.skills.length != 0; else skillDef">
                        <mat-chip *ngFor="let skill of user.skills" class="chip">
                            {{ skill.other_skill ?? skill.skill.text }}
                        </mat-chip>
                    </div>
                    <ng-template #skillDef>--</ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">
                    <div class="no-data" *ngIf="pageLoaded">
                        <div class="no-data-icon">
                            <mat-icon class="no-data-icon1">search</mat-icon>
                            <fa-icon [icon]="faUsers" class="no-data-icon2"></fa-icon>
                        </div>
                        <div class="no-data-title">No users found</div>
                        <p>There are no users that match your current filters. Try removing some of them to get better
                            results.</p>
                    </div>
                    <div class="no-data" *ngIf="pageLoaded">
                        <div class="no-data-icon">
                            <mat-icon class="no-data-icon1">search</mat-icon>
                            <fa-icon [icon]="faUsers" class="no-data-icon2"></fa-icon>
                        </div>
                        <div class="no-data-title">No users available</div>
                        <p>There are no users in this roster yet.</p>
                    </div>
                </td>
            </tr>
        </table>
    </mat-card>
</main>