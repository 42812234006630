import { Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { Observable, map, of, startWith } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';


@Component({
  selector: 'app-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrl: './new-comment.component.scss'
})
export class NewCommentComponent implements OnInit {

  reportSections: any;
  editingComment: boolean = false;

  commentForm = this.formBuilder.group({
    report_section: [],
    comment: [""]
  });

  constructor(
    public dialogRef: MatDialogRef<NewCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly reportService: ReportService,
    private readonly toast: ConfirmationToastComponent,
    private readonly dialogService: DialogService,
  ) {
    dialogRef.disableClose = true;
    this.reportSections = this.reportService.getReportPageList();
  }

  ngOnInit(): void {
    this.commentForm.patchValue({ report_section: this.data.report_num });
    if (this.data.edit) {
      this.editingComment = true;
      this.commentForm.patchValue({ comment: this.data.comment.comment });
    }
  }

  saveComment(): void {
    if (this.editingComment) {
      this.reportService.editReportComment({
        comment: this.commentForm.value.comment,
        section: this.commentForm.value.report_section,
        commentator_name: this.data.user.first_name + ' ' + this.data.user.last_name,
        commentator_id: this.data.user.user_id
      }, this.data.comment.report_comment_id).subscribe({
        next: (response) => {
          this.dialogRef.close(true);
        },
        error: (response) => {
          this.toast.openErrorSnackBar("There was an error updating the comment.", "Please try again.");
          this.dialogRef.close();
        },
      });
    } else {
      this.reportService.createReportComment({
        report_id: this.data.report_id,
        comment: this.commentForm.value.comment,
        section: this.commentForm.value.report_section,
        commentator_name: this.data.user.first_name + ' ' + this.data.user.last_name,
        commentator_id: this.data.user.user_id
      }).subscribe({
        next: (response) => {
          this.dialogRef.close(true);
        },
        error: (response) => {
          this.toast.openErrorSnackBar("There was an error updating the comment.", "Please try again.");
          this.dialogRef.close();
        },
      });
    }
  }
}
