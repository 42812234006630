<div class="side-line">
    <div class="text-div">
        <b>Upload image (optional)</b>
        <br>
        <span class="gray-text">Files must be under 64 MB, Files accepted: bmp, tiff, png, jpeg, jpg, gif,
            x-ms-bmp</span>
    </div>
    <div class="image-upload" [class.dragHover]="dropZoneHovered" (dragover)="dropZoneHovered = true"
        (dragleave)="dropZoneHovered = false">
        <input type="file" #fileDropRef class="fileDropRef" multiple (change)="onFileSelected($event)">
        Drop file(s) to upload or
        <button class="usa-button usa-button--secondary" for="fileDropRef">Select File(s)</button>
    </div>
    <div class="text-div" *ngIf="thumbnails.length > 0">
        <b>Images</b>
        <div class="image-container" *ngFor="let thumbnail of thumbnails">
            <div style="display: flex; position: relative;">
                <div class="thumbnail-container" *ngIf="thumbnail.image">
                    <img class="thumbnail" [src]="thumbnail.image" alt="user1">
                </div>
                <div class="thumbnail-form">
                    <b>{{ thumbnail.name }}</b>
                    <br>
                    <span class="gray-text">{{ thumbnail.extension }} • {{ thumbnail.size | byte }}</span>
                    <br>
                    <div class="progress-div" *ngIf="thumbnail.progress !== undefined">
                        <div class="progress-bg">
                            <div class="progress-front" [style]="'width: '+ thumbnail.progress*100 +'%'">

                            </div>
                        </div>
                    </div>
                    <div *ngIf="thumbnail.image">
                        <br>
                        <b>Image Caption</b>
                        <br>
                        <mat-form-field appearance="outline" class="caption-field">
                            <textarea matInput [formControl]="thumbnail.control" placeholder="Enter Image description" cdkTextareaAutosize
                              cdkAutosizeMinRows="2"></textarea>
                        </mat-form-field>
                        <span class="gray-text" *ngIf="thumbnail.control.value == ''">150 characters allowed</span>
                        <span class="gray-text" *ngIf="thumbnail.control.value != ''">{{ 150-thumbnail.control.value.length }} characters remaining</span>
                    </div>

                </div>
                <button class="delete-div" (click)="delete(thumbnail)">
                    <fa-icon [icon]="faCircleXmark" class="fa-regular fa-circle-mark delete-icon"
                        aria-label="close" matTooltipClass="tooltipClass" [matTooltip]="thumbnail.image ? 'Remove' : 'Cancel'"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>