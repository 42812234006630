<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 *ngIf="data.fireRecord.source == 'NEW'">Edit New Fire</h1>
        <h1 *ngIf="data.fireRecord.source == 'GIS'">Edit GIS Fire</h1>
        <h1 *ngIf="data.fireRecord.source == 'MERGE'">Edit Merged Fire</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="fireForm" *ngIf="data.fireRecord.source == 'NEW'">
            <mat-form-field appearance="outline">
                <mat-label>Fire or Complex Name</mat-label>
                <input matInput formControlName="fire_complex_name" required aria-label="Fire or Complex Name">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Region</mat-label>
                <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                    @for (region of regions; track region) {
                    <mat-option [value]="region">{{region}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Forest Name</mat-label>
                <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                    (input)="filter()" (focus)="clearForest()" aria-label="Forest Name">
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete requireSelection #auto="matAutocomplete"
                    (optionSelected)="forestNameSelected($event)">
                    @for (name of filtered_forest_names | async; track name) {
                    <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                    }
                </mat-autocomplete>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>ForestOrgCode</mat-label>
                <mat-select formControlName="forestOrgCode" required (selectionChange)="orgCodeSelected($event)">
                    @for (orgCode of orgCodes; track orgCode) {
                    <mat-option [value]="orgCode">{{orgCode}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated NFS Acres</mat-label>
                <input matInput formControlName="est_nfs_acres" id='nfsAcres' required aria-label="Estimated NFS Acres">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Containment Date</mat-label>
                <input matInput formControlName="containment_date" [matDatepicker]="picker" placeholder="MM/DD/YYYY"
                    aria-label="Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker" aria-label="Date Picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="est_containment_date" [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>Source: 209 report</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Assessment Status</mat-label>
                <mat-select formControlName="status">
                    @for (status of status_list; track status) {
                    <mat-option [value]="status.value" [disabled]="status.value == 3">{{status.text}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Complexity</mat-label>
                <mat-select formControlName="complexity">
                    @for (complexity of complexities; track complexity) {
                    <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon matTooltip="Learn more about BAER Assessment Complexity Levels."
                    matTooltipClass="tooltipClass" (click)="complexityModal($event)" onKeyUp="complexityModal($event)"
                    (keyup.enter)="complexityModal($event)" focusable class="info-blue-field fa-regular fa-circle-info"
                    aria-label="Info">info_outline</mat-icon>

            </mat-form-field>
        </form>

        <form id="filter_form" class="filter_form" [formGroup]="fireForm" *ngIf="data.fireRecord.source == 'GIS'">
            <div class="div-label-block">
                <h3>Fire Details</h3>
                <mat-label class="title">Fire name</mat-label>
                <mat-label>{{data.fireRecord.baer_name}}</mat-label>
                <mat-label class="title">Region</mat-label>
                <mat-label>{{data.fireRecord.region}}</mat-label>
                <mat-label class="title">Forest name</mat-label>
                <mat-label>{{data.fireRecord.forest_name | forest_filter}}</mat-label>
                <mat-label class="title">ForestOrgCode</mat-label>
                <mat-label>{{data.fireRecord.forest_org_code}}</mat-label>
                <mat-label class="title">Fire Start Date</mat-label>
                <mat-label>{{data.fireRecord.fire_discovery_date | date:"MM/dd/YYYY" }}</mat-label>
                <mat-label class="title">Total Fire Acres</mat-label>
                <mat-label>{{data.fireRecord.poly_acres | number: '1.0-0'}}</mat-label>
                <mat-label class="title">Containment Date (GIS)</mat-label>
                <mat-label>{{data.fireRecord.containment_date | date:"MM/dd/YYYY"}}</mat-label>
                <mat-label class="title">Estimated NFS Acres</mat-label>
                <mat-label>{{data.fireRecord.acres_fsonly | number: '1.0-0'}}</mat-label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="est_containment_date" [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>Source: 209 report</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Assessment Status</mat-label>
                <mat-select formControlName="status">
                    @for (status of status_list; track status) {
                    <mat-option [value]="status.value" [disabled]="status.value == 3">{{status.text}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Complexity</mat-label>
                <mat-select formControlName="complexity">
                    @for (complexity of complexities; track complexity) {
                    <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon matTooltip="Learn more about BAER Assessment Complexity Levels."
                    matTooltipClass="tooltipClass" (click)="complexityModal($event)" onKeyUp="complexityModal($event)"
                    (keyup.enter)="complexityModal($event)" focusable class="info-blue-field fa-regular fa-circle-info"
                    aria-label="Info">info_outline</mat-icon>
            </mat-form-field>
        </form>

        <form id="filter_form" class="filter_form" [formGroup]="fireForm" *ngIf="data.fireRecord.source == 'MERGE'">
            <mat-accordion [togglePosition]="'before'">
                <mat-expansion-panel hideToggle="true" #fireTableExp>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon *ngIf="!fireTableExp.expanded">chevron_right</mat-icon>
                            <mat-icon *ngIf="fireTableExp.expanded">expand_more</mat-icon>
                            Merged Fires ({{mergeListLength}})
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table mat-table [dataSource]="mergeList">
                        <ng-container matColumnDef="BAER_name">
                            <th mat-header-cell *matHeaderCellDef>Fire/Complex Name</th>
                            <td mat-cell *matCellDef="let fire">{{fire.baer_name}}</td>
                            <td mat-footer-cell *matFooterCellDef>Total Acres</td>
                        </ng-container>
                        <ng-container matColumnDef="forestOrgCode">
                            <th mat-header-cell *matHeaderCellDef>ForestOrg Code</th>
                            <td mat-cell *matCellDef="let fire">{{fire.forest_org_code}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="containment_date">
                            <th mat-header-cell *matHeaderCellDef>Containment Date (GIS)</th>
                            <td mat-cell *matCellDef="let fire">{{fire.containment_date | date:"MM/dd/YYYY"}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="est_nfs_acres">
                            <th mat-header-cell *matHeaderCellDef>Est. NFS Acres</th>
                            <td mat-cell *matCellDef="let fire">{{fire.acres_fsonly | number: '1.0-0'}}</td>
                            <td mat-footer-cell *matFooterCellDef> {{est_nfs_acres_total | number: '1.0-0'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fire_acres">
                            <th mat-header-cell *matHeaderCellDef>Total Fire Acres</th>
                            <td mat-cell *matCellDef="let fire">{{fire.poly_acres | number: '1.0-0'}}</td>
                            <td mat-footer-cell *matFooterCellDef> {{total_fire_acres_total | number: '1.0-0'}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-form-field appearance="outline">
                <mat-label>Merged Fire/Complex Name</mat-label>
                <input matInput formControlName="fire_complex_name" required aria-label="Merge Fire/Complex Name">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Containment Date</mat-label>
                <input matInput formControlName="containment_date" [matDatepicker]="picker" placeholder="MM/DD/YYYY"
                    aria-label="Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker" aria-label="Date Picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="est_containment_date" [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>Source: 209 report</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Assessment Status</mat-label>
                <mat-select formControlName="status">
                    @for (status of status_list; track status) {
                    <mat-option [value]="status.value" [disabled]="status.value == 3">{{status.text}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Complexity</mat-label>
                <mat-select formControlName="complexity">
                    <mat-option [value]="null">--</mat-option>
                    @for (complexity of complexities; track complexity) {
                    <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon matTooltip="Learn more about BAER Assessment Complexity Levels."
                    matTooltipClass="tooltipClass" (click)="complexityModal($event)" onKeyUp="complexityModal($event)"
                    (keyup.enter)="complexityModal($event)" focusable class="info-blue-field fa-regular fa-circle-info"
                    aria-label="Info">info_outline</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Est. NFS Acres</mat-label>
                <input matInput formControlName="est_nfs_acres" id='nfsAcres' required aria-label="Estimated NFS Acres">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </form>

    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Close" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Save" class="usa-button" [disabled]="!fireForm.valid" (click)="editFire()">Save</button>
        </div>
    </div>
</div>