import { Component, OnChanges, SimpleChanges, input, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { UserMemberService } from 'src/app/services/user-member.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnChanges {
  user_id = input.required<number>();
  edit_contact = signal<boolean>(false);
  edit_availability = signal<boolean>(false);
  edit_skills = signal<boolean>(false);
  refreshUser = new Subject<void>();
  user: any;
  currentUser: any;
  assignmentList: any[] = [];
  editable: boolean = false;
  selfEditable: boolean = false;
  isAdmin: boolean = false;

  available_personal: string = "true";

  user_relations = ["assessments", "skills", "reportMembers", "reportMembers.report", "reportMembers.report.assessment", "agency", "baer_roles.baer_role"] // constant

  constructor(
    private readonly userService: UserService,
    private readonly userMemberService: UserMemberService,
    private readonly titleService: Title
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.userService.getUserProfile().then((user) => {
      this.currentUser = user;
      this.loadUserInfo();
    })
  }

  loadUserInfo() {
    let user_id = this.user_id();
    if ((this.user_id() ?? 0) == 0)
      user_id = this.currentUser.user_id;
    this.userService.getUser(user_id, this.user_relations).subscribe((data) => {
      this.user = data.users[0];
      this.sortFlattenAssessments(this.user)
      this.available_personal = String(this.user.available_personal ?? true);

      // 8 - Forest BAER Coordinator
      const is8 = this.currentUser.baer_roles?.find((obj: any) => [8].includes(obj.baer_role.value))

      if (this.user.user_id == this.currentUser.user_id || is8) {
        this.selfEditable = true;
        this.editable = true;
        this.titleService.setTitle("BAER - My Profile")
      }
      else {
        this.titleService.setTitle("BAER - " + data.users[0].first_name + ' ' + data.users[0].last_name)
      }
      // 3 - BAER Regional Coordinator
      // 4 - BAER National Coordinator
      // 5 - BAER Unit Coordinator
      // 7 - Washington Office Director
      // 8 - Forest BAER Coordinator
      // 9 - Forest BAR Coordinator
      // 11 - Forest Disaster Coordinator
      // 12 - RO BAER Coordinator
      // 13 - RO BAR Coordinator
      // 15 - RO Disaster Coordinator
      // 16 - WO Coordination
      // 17 - WO Logistics
      const iscoordinator = this.currentUser.baer_roles?.find((obj: any) => [0, 3, 4, 5, 7, 8, 9, 11, 12, 13, 15, 16, 17].includes(obj.baer_role.value))
      if (iscoordinator) this.editable = true;
      // 0-BAER Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 16-WO Coordination
      // 17-WO Logistics
      const isAdmin = this.currentUser.baer_roles?.find((obj: any) => [0, 4, 7, 16, 17].includes(obj.baer_role.value))
      if (isAdmin) this.isAdmin = true;

      let assignmentTempList: any[] = [];

      for (let reportMember of this.user.reportMembers) {
        assignmentTempList.push({
          'assessment_id': reportMember.report.assessment_id,
          'baer_name': reportMember.report.assessment.baer_name + ' (' + reportMember.report.report_num + ')',
          'report_id': reportMember.report.report_id,
          'forest_name': reportMember.report.assessment.forest_name,
          'skills': reportMember.skills,
          'start_date': reportMember.report.assessment.tentative_start_week,
          'close_date': reportMember.report.assessment.close_out_date,
        })
      }
      for (let assessment of this.user.assessments) {
        if (!assignmentTempList.find((assignment: any) => assignment.assessment_id == assessment.assessment_id))
          assignmentTempList.push({
            'assessment_id': assessment.assessment_id,
            'baer_name': assessment.baer_name,
            'report_id': 'N/A',
            'forest_name': assessment.forest_name,
            'skills': [],
            'start_date': assessment.tentative_start_week,
            'close_date': assessment.close_out_date,
          })
      }
      this.assignmentList = assignmentTempList;
    })

    // Refreshes user when .next() is called on refreshUser
    this.refreshUser.asObservable().subscribe(() => {
      this.loadUserInfo();
    })
  }

  availabilityChanged() {
    let available_personal: boolean | null | undefined = (this.available_personal == "true")

    this.userService.editUserDetails({
      user_id: this.user.user_id,
      available_personal: available_personal,
    }).subscribe({
      next: (response) => {
      },
      error: (response) => {
        console.log("There was an error updating the user.", "Please try again.");
      },
    })
  }

  sortFlattenAssessments(user: any) {
    const today = new Date();
    let assessments: any = [];
    let filteredMembers = user.reportMembers.filter((x: any) => !x.demobilize)
    let filteredReports: any[] = []
    filteredMembers.forEach((x: any) => filteredReports.push(x.report));
    assessments = [...assessments, ...user.assessments];
    for (let report of filteredReports) {
      assessments = [...assessments, ...[report.assessment]];
    }
    assessments = assessments.filter((x: any) => (new Date(x.close_out_date)) > today);
    assessments.sort((a: any, b: any) => a.close_out_date > b.close_out_date);
    user.filteredAssessment = assessments[0];

    let tooltip = '';
    if (user.filteredAssessment) {
      tooltip = 'Fire Assignment:\nFire name: ' + user.filteredAssessment.baer_name
      tooltip += '\nStart Date: ' + (new Date(user.filteredAssessment.tentative_start_week)).toLocaleDateString("en-US")
      tooltip += '\nClose Out: ' + (new Date(user.filteredAssessment.close_out_date)).toLocaleDateString("en-US")
    }
    user.assessmentTooltip = tooltip;
  }
}
