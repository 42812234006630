import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';


@Component({
  selector: 'app-delete-comment',
  templateUrl: './delete-comment.component.html',
  styleUrl: './delete-comment.component.scss'
})
export class DeleteCommentComponent {
  userText: string;
  
  constructor(
    public dialogRef: MatDialogRef<DeleteCommentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly reportService: ReportService,
    private readonly toast: ConfirmationToastComponent,
    private readonly dialogService: DialogService,
  ) {
    dialogRef.disableClose = true;
    this.userText = "Are you sure you want to delete this comment?"
  }

  deleteUser(): void {
    this.dialogService.openSpinner()
    this.reportService.deleteReportComment(this.data.comment_id).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openSuccessSnackBar(`This comment has been deleted.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openErrorSnackBar("There was an error deleting the user", "Please try deleting the user again.");
        this.dialogRef.close(false);
      },
    })
  }

  close(): void {
    this.dialogRef.close(false);
  }
}