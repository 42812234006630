<mat-card>
  <mat-card-header>
    <mat-card-title>AVAILABILITY</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="contact_form" [formGroup]="availabilityForm">
      <p>Please get supervisor approval, both timing and number of assignment you may accept, prior to making yourself
        available on the roster. This is a great pre-season discussion.</p>
      <p>International assignments will only be pulled from active national roster members.</p>
      <div class="outer_div">
        <div class="inner_div">
          <div class="title">Availability<div class="sub-title">(Select all that apply)</div>
          </div>
          <div class="checkbox-list">
            <mat-checkbox formControlName="available_local">
              Available Locally
            </mat-checkbox>
            <mat-checkbox formControlName="available_region">
              Available Regionally
            </mat-checkbox>
            <mat-checkbox formControlName="available_nation">
              Available Nationally
            </mat-checkbox>
          </div>
          <div class="title">Conflict dates<div class="sub-title">(List known unavailable dates)</div>
          </div>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="conflict_dates" placeholder="Add conflict dates" cdkTextareaAutosize
              cdkAutosizeMinRows="3"></textarea>
          </mat-form-field>
          <br>
        </div>
        <div class="inner_div" *ngIf="isAdmin">
          <div class="title">Certification</div>
          <div class="checkbox-list">
            <mat-checkbox formControlName="nis_baer">
              NIS-BAER
            </mat-checkbox>
            <mat-checkbox formControlName="vgis">
              VGIS
            </mat-checkbox>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-dialog-actions>
    <div class="button-row">
      <button class="usa-button" (click)="save()">Save</button>
      <button class="usa-button usa-button--secondary" (click)="cancel()">Cancel</button>
    </div>
  </mat-dialog-actions>
</mat-card>