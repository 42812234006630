import { Component } from '@angular/core';
import {environment} from "../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BAER';
  constructor(
    public router: Router
  ) {
    console.log("current environment: " + environment.state);

  }
}
