<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title class="delete-title">Delete Comment</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="delete-close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="title_light" [innerHtml]="userText"></div>
        <div class="alert-text">
            <mat-icon class="material-icons-outlined alerting-icon">error_outline</mat-icon>
            This action cannot be undone!
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row-always">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button class="usa-button delete" (click)="deleteUser()">
                <i class="material-icons">delete</i>
                Delete</button>
        </div>
    </div>
</div>