<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>
            <mat-icon class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
            BAER 2500-8 Report Types</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <p>
            <b>
                Funding Request for Estimated Emergency Stabilization Funds
            </b>
            <br>
            Select this report type to initiate or update a request for emergency stabilization funding authorization.
        </p>
        <p>
            <b>
                No Treatment Recommendation
            </b>
            <br>
            Select this report type to report a decision to recommend no burned area emergency rehabilitation watershed treatment.
        </p>
        <p>
            <b>
                Pre-assessment Funding Request
            </b>
            <br>
            Select this report to initiate a request for emergency stabilization funding authorization prior to completion of the assessment. 
            This type of request should be used rarely when emergency actions need to be taken prior to assessment completion. 
            Any treatments approved under this request type must be fully described and accounted for in subsequent funding requests.
        </p>
    </mat-dialog-content>
</div>