import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Watershed } from '../models/watershed.model';
import { ForestTreament } from '../models/forestTreatment.model';
import { ReportForest } from '../models/reportForest.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    reportRelations = '&relations=users,users.skills,users.user,users.user.skills,users.user.skills.skill,assessment,supporting_files,supports,soil_burn_severities,comments,forests,forests.treatments,report_status,stateCounties,watersheds';

    constructor(private readonly httpClient: HttpClient) { }

    getReportList(assessment_id: number, reportRelations: string): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?assessment_id=${assessment_id}`
        return this.setNotStartedStatus(this.httpClient.get(
            `${environment.apiUrl}/report${queryParams}${reportRelations}`, { params: params }
        ));
    }
    getReport(report_id: number, relations?: string[]): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?report_id=${report_id}`

        if (relations && relations.length > 0) {
            queryParams += "&relations=" + relations.join(",");
        }
        
        return this.setNotStartedStatus(this.httpClient.get(
            `${environment.apiUrl}/report${queryParams}`, { params: params }
        ));
    }

    createReport(assessment: any, report_num: number): Observable<any> {
        let data = {
            assessment_id: assessment.assessment_id,
            report_status_id: 0, // In Progress
            report_num: report_num,
        };
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    updateReport(data: {
        report_id: number,
        report_status_id?: number,
        supports?: any,

        // report type
        report_type?: string,
        initial_report_type?: string,

        // burned area
        watersheds?: Watershed[],
        baer_name?: string,
        IRWIN_unique_fire_identifier?: number,
        job_code?: number,
        vegetation_types?: string,
        dominant_soils?: string,
        geologic_types?: string,
        other_roads?: string,

        fire_discovery_date?: any,
        containment_date_est?: any,
        containment_date?: any,

        //watersheds
        soil_burn_severities?: any[],
        erosion_hazard?: string,
        erosion_potential?: string,
        debris_potential?: string,
        vegetative_recovery?: string,
        hydrolic_response?: string,

        // summary of analysis
        background?: string,
        threats_human?: string,
        threatsproperty?: string,
        threats_natural?: string,
        threats_cultural?: string,
        threats_emergency?: string,
        treat_prob_land?: number | null,
        treat_prob_channel?: number,
        treat_prob_trails?: number,
        treat_prob_safety?: number,
        critical_values?: string,
        cost_no_action?: string,
        cost_alternative?: string,

        //recommended treatments
        forests?: ReportForest[],

        //emergency stabilzation
        assessment_cost?: string,

        page_1_status_id?: number,
        page_2_status_id?: number,
        page_3_status_id?: number,
        page_4_status_id?: number,
        page_5_status_id?: number,
        page_6_status_id?: number,
        page_7_status_id?: number,
        page_8_status_id?: number,

        // Many more options to be added
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    getReportComments(report_id: number): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?report_id=${report_id}`
        return this.httpClient.get(
            `${environment.apiUrl}/report-comment${queryParams}`, { params: params }
        );
    }

    createReportComment(data: any): Observable<any> {
        let params = new HttpParams();
        return this.httpClient.post(
            `${environment.apiUrl}/report-comment`, data, { params: params }
        );
    }

    editReportComment(data: any, comment_id: any): Observable<any> {
        let params = new HttpParams();
        return this.httpClient.put(
            `${environment.apiUrl}/report-comment/${comment_id}`, data, { params: params }
        );
    }

    deleteReportComment(comment_id: any): Observable<any> {
        let params = new HttpParams();
        return this.httpClient.delete(
            `${environment.apiUrl}/report-comment/${comment_id}`, { params: params, responseType: "blob" as any },
        );
    }

    getReportPageList() {
        return [
            {pageNum: 1, pageName: 'Report Type'},
            {pageNum: 2, pageName: 'Burned Area Description'},
            {pageNum: 3, pageName: 'Watershed Condition'},
            {pageNum: 4, pageName: 'Summary of Analysis'},
            {pageNum: 5, pageName: 'Recommended Treatment Narratives'},
            {pageNum: 6, pageName: 'Emergency Stabilization Treatments & Requested Funds'},
            {pageNum: 7, pageName: 'Team & Supporting Documents'},
            {pageNum: 8, pageName: 'Select Forest Unit Line Officer(s)'}
        ]
    }

    /**
   * Sends request for support for report, updates report with data
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
    requestSupport(data: {
        report_id: any,
        support_needed_type?: string,
        support_skills?: string,
        support_details?: string,
        other_skill?: string,
    }): Observable<any> {
        let body = {
            report_id: data.report_id,
            supports: [{
                support_type: data.support_needed_type,
                support_skills: data.support_skills,
                additional_details: data.support_details,
                support_completed: false,
                other_skill: data.other_skill,
            }],
        }
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, body, { params: params }
        );
    }

    createBurnSeverity(data: {
        report_id: number,
        ownership: string,
        acres_burned: number,
        percent_burned: number,
        burn_acres_unburned: number,
        burn_acres_low: number,
        burn_acres_moderate: number,
        burn_acres_high: number,
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report-soil-burn-severity`, data, { params: params }
        );
    }

    editBurnSeverity(report_soil_burn_severity_id: number, data: {
        report_id: number,
        ownership: string,
        acres_burned: number,
        percent_burned: number,
        burn_acres_unburned: number,
        burn_acres_low: number,
        burn_acres_moderate: number,
        burn_acres_high: number,
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report-soil-burn-severity/` + report_soil_burn_severity_id, data, { params: params }
        );
    }

    getReportForestTreatments(report_id: number) {
        let report_info = [
            {
                report_forest_id: 1,
                region: '02',
                forest_org_code: '3225',
                forest_name: 'a forest name',
                //all info in report_forest table
                forest_treatment: [
                    {
                        forest_treatment_id: 1,
                        forest_id: 1, //foregn key
                        treatment_type_id: 1,
                        treatment_type: {
                            treatment_type_id: 1,
                            treatment_type: 'Plants'
                        },
                        treatment_option_id: 1,
                        treatment_option: {
                            treatment_option_id: 1,
                            treatment_option: 'P1a. Invasive EDRR',
                            code: 'some code',
                            unit_of_measure: 'Cost/Site'
                        },
                        unit_cost: 23.32,
                        unit_qty: 5,
                        narrative: 'narrative here',
                        cost_approval: 343.45,
                        recommendation_id: 1
                    }
                ]
            },
            {
                report_forest_id: 2,
                region: '02',
                forest_org_code: '3226',
                forest_name: 'another forest name',
                //all info in report_forest table
                forest_treatment: [
                    {
                        forest_treatment_id: 1,
                        forest_id: 1, //foregn key
                        treatment_type_id: 1,
                        treatment_type: {
                            treatment_type_id: 1,
                            treatment_type: 'Plants'
                        },
                        treatment_option_id: 1,
                        treatment_option: {
                            treatment_option_id: 1,
                            treatment_option: 'P1a. Invasive EDRR',
                            code: 'some code',
                            unit_of_measure: 'Cost/Site'
                        },
                        unit_cost: 27.32,
                        unit_qty: 20,
                        narrative: 'narrative here',
                        cost_approval: 343.45,
                        recommendation_id: 1
                    }
                ]
            },
            {
                report_forest_id: 3,
                region: '02',
                forest_org_code: '3227',
                forest_name: 'EMPTY forest name',
                //all info in report_forest table
                forest_treatment: []
            }
        ]

        return Promise.resolve(report_info);
    }

    uploadFile(data: any): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/file`, data, { params: params },
        );
    }

    getThumbnail(supporting_files_id: number): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.get(
            `${environment.apiUrl}/file/${supporting_files_id}/thumbnail`, { params: params, responseType: "blob" as any },
        );
    }

    deleteFile(supporting_files_id: number): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.delete(
            `${environment.apiUrl}/file/${supporting_files_id}`, { params: params, responseType: "blob" as any },
        );
    }

    updateFile(supporting_files_id: number, data: any): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/file/${supporting_files_id}`, data, { params: params },
        );
    }

    getTreatmentOptions(): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.get(
            `${environment.apiUrl}/metadata/treatment-options`, { params: params },
        );
    }

    setNotStartedHelper(report: any) {
        if (report.assessment.assessment_status_id == 3) { // In Progress
            let startDate = new Date(report.assessment.tentative_start_week)
            if (new Date() < startDate) {
                report.report_status_id = -10
                report.report_status = {
                    report_status: "Not Started",
                    report_status_id: -10,
                }
            }
        }
    }

    setNotStartedStatus(result: Observable<any>): Observable<any> {
        let subject = new Subject<any>()
        result.subscribe({
            next: (response) => {
                if (response.reports) {
                    response.reports.forEach(this.setNotStartedHelper)
                }
                subject.next(response)
            },
            error: (response) => {
                subject.error(response)
            },
            complete: () => {
                subject.complete()
            }
        })
        return subject
    }

    createWaterShed(report_id: number): Observable<any> {
        let data = {
            report_id: report_id
        }
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/watershed`, data, { params: params },
        );
    }
}