import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReportService } from 'src/app/services/report.service';
import { Editor, Toolbar } from 'ngx-editor';
import { Subject } from 'rxjs';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-analysis-summary',
  templateUrl: './analysis-summary.component.html',
  styleUrls: ['./analysis-summary.component.scss']
})


export class AnalysisSummaryComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  editor1!: Editor;
  editor2!: Editor;
  editor3!: Editor;
  editor4!: Editor;
  editor5!: Editor;
  editor6!: Editor;
  editor7!: Editor;
  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  report = input.required<any>();
  nav = input.required<string>();
  forests = []
  saveSubject = new Subject<any>();

  // close = input.required<boolean>();
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  form = this.formBuilder.group({
    background: [""],
    threats_human: [""],
    threatsproperty: [""],
    threats_natural: [""],
    threats_cultural: [""],
    treat_prob_land: [0],
    treat_prob_channel: [0],
    treat_prob_trails: [0],
    treat_prob_safety: [0],
    critical_values: ["", Validators.pattern(this.reg)],
    cost_no_action: [""],
    cost_alternative: [""],
  });


  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,   
    public readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {
    this.editor1 = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.editor5 = new Editor();
    this.editor6 = new Editor();
    this.editor7 = new Editor();

    this.form.patchValue({
      background: this.report().background ?? "",
      threats_human: this.report().threats_human ?? "",
      threatsproperty: this.report().threatsproperty ?? "",
      threats_natural: this.report().threats_natural ?? "",
      threats_cultural: this.report().threats_cultural ?? "",
      treat_prob_land: this.report().treat_prob_land as number | null,
      treat_prob_channel: this.report().treat_prob_channel as number | null,
      treat_prob_trails: this.report().treat_prob_trails as number | null,
      treat_prob_safety: this.report().treat_prob_safety as number | null,
      critical_values: this.report().critical_values,
      cost_no_action: this.report().cost_no_action ?? "",
      cost_alternative: this.report().cost_alternative ?? "",
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 5 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 3 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }


  save() {
    this.saveSubject.next(null)
    
    if (!this.form.dirty) {
      // Don't save if nothing has changed
      return
    }

    // Convert blank number fields to undefined
    let numbers = ["treat_prob_land", "treat_prob_channel", "treat_prob_trails", "treat_prob_safety"]
    for (let field of numbers) {
      if (this.form.get(field)?.value === "") {
        this.form.patchValue({[field]: null})
      } else if (this.form.get(field)?.value != null) {
        this.form.patchValue({[field]: +this.form.get(field)?.value})
      }
    }

    let allFieldsFilled = true

    for (let field in this.form.controls) {
      if (this.form.get(field)?.value === null || this.form.get(field)?.value === "") {
        allFieldsFilled = false
      }
    } 
    
    this.reportService.updateReport({
      report_id: this.report().report_id,
      background: this.form.value.background as string | undefined,
      threats_human: this.form.value.threats_human as string | undefined,
      threatsproperty: this.form.value.threatsproperty as string | undefined,
      threats_natural: this.form.value.threats_natural as string | undefined,
      threats_cultural: this.form.value.threats_cultural as string | undefined,
      treat_prob_land: this.form.value.treat_prob_land,
      treat_prob_channel: this.form.value.treat_prob_channel as number | undefined,
      treat_prob_trails: this.form.value.treat_prob_trails as number | undefined,
      treat_prob_safety: this.form.value.treat_prob_safety as number | undefined,
      critical_values: this.form.value.critical_values as string | undefined,
      cost_no_action: this.form.value.cost_no_action as string | undefined,
      cost_alternative: this.form.value.cost_alternative as string | undefined,
      page_4_status_id: allFieldsFilled? 2 : 1,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
  }
}
