import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { faPersonWalkingArrowRight, faPersonWalkingArrowLoopLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-demobilize',
  templateUrl: './demobilize.component.html',
  styleUrl: './demobilize.component.scss'
})
export class DemobilizeComponent {
  faArrowRight = faPersonWalkingArrowRight;
  faArrowLeft = faPersonWalkingArrowLoopLeft;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly titleService: Title,
  ) {
    this.titleService.setTitle("BAER - Demobilize")
  }
}
