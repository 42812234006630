import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProgressSpinnerComponent } from "../components/progress-spinner/progress-spinner.component";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    spinner?: MatDialogRef<ProgressSpinnerComponent>;
    constructor(private readonly dialog: MatDialog) {}
     
    openSpinner() {
        if (!this.spinner) {
            this.spinner = this.dialog.open(ProgressSpinnerComponent, {
                panelClass: "transparent",
                disableClose: true,
            })
        }
    }

    closeSpinner() {
        if (this.spinner) {
            this.spinner.close()
        }
        this.spinner = undefined
    }
}