import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReportService } from 'src/app/services/report.service';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-team-supporting',
  templateUrl: './team-supporting.component.html',
  styleUrls: ['./team-supporting.component.scss']
})


export class TeamSupportingComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;
  faUsers = faUsers;

  report = input.required<any>();
  nav = input.required<string>();
  forests: string[] = []
  saveSubject = new Subject<any>();

  pageLoaded = false;

  forestContacts: any[] = [];
  
  users = new MatTableDataSource<any>();
  displayedColumns: string[]= [
    'name',
    'role',
    'skills'
  ];

  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  form = this.formBuilder.group({
    supporting_documents: ["",Validators.pattern(this.reg)],
    treatment_recommendations: ["",Validators.pattern(this.reg)],
    burn_severity: ["",Validators.pattern(this.reg)],
    kmz_submittal_package: ["",Validators.pattern(this.reg)],
    otherLinks: this.formBuilder.array([]),
  });
  get otherLinks() {
    return this.form.get("otherLinks") as FormArray;
  }

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
    private readonly userService: UserService,
    public readonly toast: ConfirmationToastComponent,
    ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  loadUsers() {
    this.users.data = this.report().users;
    this.userService.getUserList("", ["baer_roles.baer_role"]).subscribe(data => {
      data.users.forEach((user: any) => {
        // 8-Forest Unit BAER Coordinator
        // 9-Forest BAR Coordinator

        if (!user.baer_roles.find((obj: any) =>
          [8, 9].includes(obj.baer_role.value))) return

        if (!this.forests.includes(user.forest_name)) return

        this.forestContacts.push(user)

        user.showRoles = []
        user.baer_roles.forEach((role: any) => {
          if ([8, 9].includes(role.baer_role.value))
            user.showRoles.push(role.baer_role.text)
        })
      })
      this.pageLoaded = true;
    })
  }

  ngOnInit(): void {
    this.forests = this.report().assessment.forest_name.split(",")
    this.loadUsers();
    this.addFileLink();
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 8 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 6 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    this.saveSubject.next(null)
    this.reportService.updateReport({
      report_id: this.report().report_id,
      page_7_status_id: 2,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }   

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }

  addFileLink() {
    this.otherLinks.push(this.formBuilder.group({
      file_name: "",
      link: ["",Validators.pattern(this.reg)],
    }))
  }

  removeFileLink(index: number) {
    this.otherLinks.removeAt(index)
  }
}
