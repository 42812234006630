import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  expanded: boolean = false;
  user: any;
  isLoggedIn: boolean = false;
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkCredentials();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe({next: events => {
          this.isLoggedIn = this.authService.checkCredentials();
        }});
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
