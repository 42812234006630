<mat-card>
  <mat-card-header>
    <mat-card-title>FIRE LOCATION & STATUS</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Fire">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="fire">
    <div class="data-div">
      <p class="data-title">Fire or complex name</p>
      <p class="data-info">{{fire.baer_name}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Forest Name</p>
      <p class="data-info">{{fire.forest_name}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Region</p>
      <p class="data-info">{{fire.region}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Forest Org Code</p>
      <p class="data-info">{{fire.forest_org_code}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Fire Start Date</p>
      <p class="data-info">{{(fire.fire_discovery_date | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Estimated NFS Acres</p>
      <p class="data-info">{{fire.acres_fsonly || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Total Fire Acres</p>
      <p class="data-info">{{(fire.poly_acres | number: '1.0-0') || "--"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Containment Date (GIS)</p>
      <p class="data-info">{{(fire.containment_date | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Estimated Containment Date</p>
      <p class="data-info">{{(fire.containment_date_est | date:"MM/dd/YYYY") || "--" }}</p>
    </div>
    <div class="data-div">
      <p class="data-title">BAER Assessment Status</p>
      <mat-chip [ngClass]="'chip-'+fire.assessment_status_id">
        {{fire.assessment_status_id | statusBadge}}
      </mat-chip>
    </div>
    <div class="data-div">
      <p class="data-title">Complexity</p>
      <div [ngSwitch]="fire.fire_complexity_id">
        <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
        <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
        <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
        <div *ngSwitchDefault>--</div>
      </div>
    </div>
    <div class="data-div">
      <!-- Filler -->
    </div>
  </mat-card-content>
</mat-card>