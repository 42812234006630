import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { environment } from 'src/environments/environment';
import {Params, Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly isBrowser: boolean;

  constructor(
    private readonly _http: HttpClient,
    private readonly router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  retrieveToken(code: string, callback: () => any) {
    return this._http.post(`${environment.apiUrl}/auth/token`,
      { code })
      .subscribe(
        {
          next: (token: any) => {
            if (this.isBrowser) {
              sessionStorage.setItem("access_token", token.access_token);
              sessionStorage.setItem("id_token", token.id_token);
            }
            callback();
          },
          error: () => {
            if (this.isBrowser) {
              // TODO: can implement a better view for this
              alert('Invalid Credentials.');
            }
            this.authenticate();
          }
        });
  }

  validateState(state: string) {
    if (this.isBrowser && state === sessionStorage.getItem('state')) {
      return;
    }
    if (this.isBrowser) {
      // TODO: can implement a better view for this
      alert('Invalid State. Redirect to Login...');
    }
    this.authenticate();
  }

  checkCredentials() {
    return !(this.isBrowser && (!sessionStorage.getItem('access_token') || !sessionStorage.getItem('id_token')));
  }

  logout() {
    if (this.isBrowser) {
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('id_token');
    }
    this.router.navigate(['/login']);
  }

  authenticate() {
    if (this.isBrowser) {
      const state = window.crypto.randomUUID().substring(0, 7);
      sessionStorage.setItem('state', state);
      window.location.href = `${environment.authUrl}/authorize?client_id=${environment.clientId}&redirect_uri=${environment.redirectUrl}&scope=openid&response_type=code&response_mode=form_post&state=${state}&code_challenge_method=S256`;
    }
    return false;
  }

  redirect = () => {
    const previousUri = this.isBrowser && sessionStorage.getItem("previous_uri") || undefined;
    if (previousUri) {
      const queryParams = JSON.parse(sessionStorage.getItem("previous_query_params") ?? "") as Params;
      this.router.navigate([decodeURI(previousUri)], {...(queryParams && {queryParams})})
    } else {
      this.router.navigate(['/'])
    }
  }

}
