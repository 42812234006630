import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'login-redirect',
  template: ""
})
export class LoginRedirectComponent implements OnInit, OnDestroy {
  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.dialogService.openSpinner()
    if (!this.authService.checkCredentials()) {
      this.route.queryParams.subscribe({
        next: params => {
          this.authService.validateState(params['state']);
          this.authService.retrieveToken(params['code'], this.authService.redirect);
        },
        error: err => {
          console.error(err);
          this.router.navigate(['login']);
        }
      })
    } else {
      this.authService.redirect();
    }
  }

  ngOnDestroy() {
    this.dialogService.closeSpinner();
  }

}
