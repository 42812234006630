<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<link rel="icon" type="image/png" href="assets/img/favicon.png">
<meta name="theme-color" content="#FFFFFF">
<meta property="og:title" content="Burn Area Emergency Response">
<section class="usa-banner">
    <div class="usa-banner">
        <div class="usa-accordion">
            <header class="usa-banner__header">
                <div class="usa-banner__inner">
                    <div class="grid-col-auto">
                        <img class="usa-banner__header-flag" src="\assets\img\us_flag_small.png" alt="U.S. flag">
                    </div>
                    <div class="grid-col-fill tablet:grid-col-auto">
                        <p class="usa-banner__header-text">An official website of the United States government</p>
                        <p class="usa-banner__header-action" aria-hidden="true">Here’s how you know</p>
                    </div>
                    <button class="usa-accordion__button usa-banner__button" aria-controls="gov-banner"
                            id="usa-banner-toggle"
                        (click)="toggle()" [attr.aria-expanded]="expanded">
                        <span class="usa-banner__button-text">Here's how you know</span>
                    </button>
                </div>
            </header>
            <div class="usa-banner__content usa-accordion__content" id="gov-banner" [hidden]="!expanded">
                <div class="grid-row grid-gap-lg">
                    <div class="usa-banner__guidance tablet:grid-col-6">
                        <img class="usa-banner__icon usa-media-block__img" src="\assets\img\icon-dot-gov.svg"
                            alt="Dot gov">
                        <div class="usa-media-block__body">
                            <p>
                                <strong>Official websites use .gov</strong>
                                <br>
                                A <strong>.gov</strong> website belongs to an official government organization in the
                                United States.
                            </p>
                        </div>
                    </div>
                    <div class="usa-banner__guidance tablet:grid-col-6">
                        <img class="usa-banner__icon usa-media-block__img" src="\assets\img\icon-https.svg" alt="Https">
                        <div class="usa-media-block__body">
                            <p>
                                <strong>Secure .gov websites use HTTPS</strong>
                                <br>
                                A <strong>lock</strong> (
                                <span class="icon-lock"><svg xmlns="http://www.w3.org/2000/svg" width="52" height="64"
                                        viewBox="0 0 52 64" class="usa-banner__lock-image" role="img"
                                        aria-labelledby="banner-lock-title-default banner-lock-description-default"
                                        >
                                        <title id="banner-lock-title-default">Lock</title>
                                        <desc id="banner-lock-description-default">A locked padlock</desc>
                                        <path fill="#000000" fill-rule="evenodd"
                                            d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z">
                                        </path>
                                    </svg></span>
                                ) or <strong>https://</strong> means you’ve safely connected to the .gov website. Share
                                sensitive information only on official, secure websites.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<header class="usa-header usa-header--extended">
    <div class="usa-navbar">
        <div class="fs-site-branding">
            <div class="display-flex flex-align-center margin-right-2">
                <a href="https://www.usda.gov" data-kmt="1"><img class="height-4 margin-right-1"
                        src="https://www.fs.usda.gov/themes/custom/fs_uswds/usda-symbol.svg" alt="usda logo"></a>
                <a href="https://www.fs.usda.gov/" data-kmt="1"><img class="height-5"
                        src="https://www.fs.usda.gov/themes/custom/fs_uswds/logo.svg" alt="usfs shield"></a>
            </div>
            <div class="display-flex flex-column flex-justify-center line-height-body-2 mobile:margin-bottom-05">
                <a class="mobile:font-body-lg text-no-underline text-bold text-base-darkest"
                    href="https://www.fs.usda.gov/" data-kmt="1">Forest Service</a>
                <a class="font-body-md text-no-underline text-base-darker" href="https://www.usda.gov/" data-kmt="1">
                    <span class="mobile-lg:display-none">U.S.D.A</span>
                    <span class="display-none mobile-lg:display-block text-uppercase">U.S. DEPARTMENT OF
                        AGRICULTURE</span>
                </a>
            </div>
            <div
                class=" title_text display-flex flex-column flex-justify-center line-height-body-2 mobile:margin-bottom-05">
                <a href="#" id="burned-area">Burned Area</a>
                <a href="#" id="emergency-response">Emergency Response</a>
            </div>
        </div>
        <app-header-menu *ngIf="isLoggedIn"></app-header-menu>
    </div>
    <!-- Navigation Begin -->
    <app-nav *ngIf="isLoggedIn"></app-nav>
    <!-- Navigation End -->
</header>
