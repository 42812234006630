import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Editor, Toolbar } from 'ngx-editor';
import { ReportService } from 'src/app/services/report.service';
import { ReportForest } from 'src/app/models/reportForest.model';
import { ForestTreament } from 'src/app/models/forestTreatment.model';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-recommended-treatment',
  templateUrl: './recommended-treatment.component.html',
  styleUrls: ['./recommended-treatment.component.scss']
})


export class RecommendedTreatmentComponent implements OnChanges, OnInit {

  faTrash = faTrash;
  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  editors: Editor[] = [];

  report = input.required<any>();
  nav = input.required<string>();
  forests: any[] = []
  treatments: any[][] = [];

  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  treatmentTypes: any[] = []

  im = new Inputmask("currency", {
    groupSeparator: ',',
    prefix: '$',
    allowMinus: false
  });

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
    public readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Report Type")
    this.initTreamentOptions()
  }

  ngOnInit(): void {
    this.forests = this.report().forests
    this.forests.forEach((forest: ReportForest) => {

      this.treatments.push([])

      forest.treatments?.forEach((treatment: ForestTreament) => {
        let formGroup = this.formBuilder.group({
          treatment_type: [treatment.treatment_type_id],
          treatment_option: [treatment.treatment_option_id],
        });

        let specs: any[] = []
        treatment.treatment_specifications?.forEach((spec: any) => {
          specs.push({
            form: this.formBuilder.group({
              specification_name: [spec.specification_name, Validators.maxLength(40)],
              specification_number: [spec.specification_number],
              unit_cost: [spec.unit_cost],
              custom_unit_qty: [spec.custom_unit_qty],
              treatment_off_nfs_land: [spec.treatment_off_nfs_land],
              treatment_outside_fire: [spec.treatment_outside_fire],
              treatment_narrative: [spec.treatment_narrative ?? ""],
              specification_sheet: [spec.specification_sheet],
              override_GIS_qty: [spec.custom_unit_qty != 0]
            }),
            treatment_specification_id: spec.treatment_specification_id,
            gis_unity_qty: spec.gis_unity_qty,
            editor: new Editor(),
          })
        })

        this.treatments[this.treatments.length - 1].push({
          form: formGroup,
          specs: specs,
          new: !treatment.from_GIS,
          forest_treatment_id: treatment.forest_treatment_id,
        })
      })
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 6 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 4 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    let reportForests: ReportForest[] = []
    let index = 0

    this.forests.forEach((forest: ReportForest) => {
      let newForest: ReportForest = {
        forest_id: forest.forest_id,
        treatments: [] as ForestTreament[],
      }
      this.treatments[index].forEach((item: any) => {
        let specs: any[] = []
        item.specs.forEach((spec: any) => {
          specs.push({
              treatment_specification_id: spec.treatment_specification_id,
              specification_name: spec.form.value.specification_name,
              specification_number: spec.form.value.specification_number,
              unit_cost: spec.form.value.unit_cost,
              custom_unit_qty: spec.form.value.custom_unit_qty,
              treatment_off_nfs_land: spec.form.value.treatment_off_nfs_land,
              treatment_outside_fire: spec.form.value.treatment_outside_fire,
              treatment_narrative: spec.form.value.treatment_narrative,
              specification_sheet: spec.form.value.specification_sheet,
          })
        })
        newForest.treatments!.push({
          forest_id: forest.forest_id,
          forest_treatment_id: item.forest_treatment_id != -1 ? item.forest_treatment_id : null,
          // unit_qty: item.form.value.unit_qty || item.gis_qty,
          treatment_type_id: item.form.value.treatment_type?.treatment_type_id,
          treatment_option_id: item.form.value.treatment_option != -1 ? item.form.value.treatment_option : null,
          treatment_specifications: specs,
        })
      })
      reportForests.push(newForest)

      index += 1
    })

    let allFieldsFilled = true

    this.reportService.updateReport({
      report_id: this.report().report_id,
      forests: reportForests,
      page_5_status_id: allFieldsFilled ? 2 : 1,
    }).subscribe({
      next: (response) => {
        console.log(response)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error saving the report`, "Please try again.");
      },
    })
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
  }

  treatmentTypeSelected(event: any, treatment: any) {
    treatment.type = event.value
  }

  standardTreatmentSelected(event: any, treatment: any) {
    treatment.standardTreatment = event.value
  }

  addNewTreatment(forestIndex: number) {
    let formGroup = this.formBuilder.group({
      unit_cost: [0],
      link: ["", Validators.pattern(this.reg)],
      custom_qty: [0],
      override_GIS_qty: [true],
      treatment_type: [""],
      treatment_option: [-1],
    });

    let newTreatment = {
      form: formGroup,
      editor: new Editor(),
      specs: [],
      new: true,
    }

    this.treatments[forestIndex].push(newTreatment);
    this.addNewSpec(newTreatment)
  }

  addNewSpec(treatment: any) {
    treatment.specs.push({
      form: this.formBuilder.group({
        specification_name: ["", Validators.maxLength(40)],
        specification_number: [1],
        unit_cost: [0],
        custom_unit_qty: [0],
        treatment_off_nfs_land: [null],
        treatment_outside_fire: [null],
        treatment_narrative: [""],
        specification_sheet: [""],
        override_GIS_qty: [false]
      }),
      editor: new Editor(),
    })
  }

  deleteTreatment(forestIndex: number, treatmentIndex: number) {
    this.treatments[forestIndex].splice(treatmentIndex, 1)
  }

  countGISTreatments(forestIndex: number) {
    let count = 0
    this.treatments[forestIndex].forEach((treatment: any) => {
      if (treatment.new == undefined) {
        count++;
      }
    })
    return count
  }

  countNewTreatments(forestIndex: number) {
    let count = 0
    this.treatments[forestIndex].forEach((treatment: any) => {
      if (treatment.new) {
        count++;
      }
    })
    return count
  }

  initTreamentOptions() {
    this.reportService.getTreatmentOptions().subscribe((response) => {
      this.treatmentTypes = []
      response.forEach((item: any) => {
        let existingTreatment = this.treatmentTypes.find((x: any) => x.type == item.treatment_type.treatment_type)
        if (existingTreatment) {
          existingTreatment.standardTreatments.push(item)
        } else {
          this.treatmentTypes.push({
            type: item.treatment_type.treatment_type,
            standardTreatments: [item],
            treatment_type_id: item.treatment_type_id,
            unit_of_measure: item.unit_of_measure,
          })
        }
      })
      this.updateTreatmentValues()
    })
  }

  updateTreatmentValues() {
    this.treatments.forEach((forest: any) => {
      forest.forEach((treament: any) => {
        let treatmentType = this.treatmentTypes.find(
          (treatType: any) => treatType.treatment_type_id == treament.form.value.treatment_type
        )
        treament.form.patchValue({
          treatment_type: treatmentType
        })
        treament.type = treatmentType
      })
    })
  }

  specChanged() {

  }
}
