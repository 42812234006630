<header class="baer-header">
  <mat-grid-list cols="12" rowHeight="474px" (window:resize)="onResize($event)">
    <mat-grid-tile [colspan]="outerGridColSpan[0]" ></mat-grid-tile>
    <mat-grid-tile [colspan]="outerGridColSpan[1]" >
      <mat-card appearance="outlined" class="login-card">
        <mat-card-content class="login-card-content">
          <mat-grid-list cols="14" rowHeight="20px">
            <mat-grid-tile [colspan]="innerDescriptionGridColSpan[0]" rowspan="6" class="login-logo-container">
              <img alt="baer-login" src="assets/img/BAER-logo.png" class="login-baer-image"/>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="innerDescriptionGridColSpan[1]" rowspan="1" *ngIf="!isMinimum"/>
            <mat-grid-tile [colspan]="innerDescriptionGridColSpan[1]" rowspan="2">
              <p class="login-title-1" [style.font-size]="subTitleFontSize" >APPLICATION LOGIN</p>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="innerDescriptionGridColSpan[1]" rowspan="4">
              <p class="login-title-2" [style.font-size]="mainTitleFontSize" >Burned Area Emergency Response (BAER)</p>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="14" rowHeight="10px">
            <mat-grid-tile colspan="14" rowspan="2" *ngIf="isMinimum"/>
            <mat-grid-tile [colspan]="innerButtonGridColSpan[0]" rowspan="5"/>
            <mat-grid-tile [colspan]="innerButtonGridColSpan[1]" rowspan="5">
              <div class="login-button" [style.width]="buttonWidth">
                <button class="usa-button" (click)="login()" [disabled]="clicked">
                  Log in to application <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="outerGridColSpan[2]" ></mat-grid-tile>
  </mat-grid-list>
</header>
