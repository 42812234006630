<mat-card>
  <mat-card-header>
    <mat-card-title>SKILLS & EXPERIENCE</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Skills">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="skill-list">
      <div class="data-title" style="padding-top: 0px;">Role/Skill</div>
      <div class="data-title" style="padding-top: 0px;">Experience level <div class="data-subtitle"
          style="flex: 100%; padding-left: 0px;">(# of assignments)</div>
      </div>
      @for (skill of skills_list; track skill) {
      <div>{{skill.text}}</div>
      <div *ngIf="skill.experience">{{skill.experience}}</div>
      <div *ngIf="!skill.experience">--</div>
      }
    </div>
    <div class="data-column">
      <div class="data-div">
        <div class="data-title"><div style="max-width: 300px;">Experience with large or complex fires </div><div class="data-subtitle">(150,000+ acres)</div>
        </div>
        <p class="data-info" *ngIf="user().large_fire_exp != null">{{user().large_fire_exp | bool_yes_no}}</p>
        <p class="data-info" *ngIf="user().large_fire_exp === null">--</p>
      </div>
      <div class="data-div">
        <div class="data-title">Required Qualifications and Equipment</div>
      </div>
      <div class="data-div">
        <div class="data-title">Current Red Card</div>
        <p class="data-info" *ngIf="user().red_card != null">{{user().red_card | bool_yes_no}}</p>
        <p class="data-info" *ngIf="user().red_card === null">--</p>
      </div>
      <div class="data-div">
        <div class="data-title">Quals<div class="data-subtitle">(List qualification codes on red card)</div>
        </div>
        <p class="data-info" *ngIf="(user().red_card_qualifications ?? '') != ''">{{user().red_card_qualifications}}</p>
        <p class="data-info" *ngIf="(user().red_card_qualifications ?? '') === ''">--</p>
      </div>
      <div class="data-div">
        <div class="data-title">Line Gear</div>
        <p class="data-info" *ngIf="user().line_gear != null">{{user().line_gear | bool_yes_no}}</p>
        <p class="data-info" *ngIf="user().line_gear === null">--</p>
      </div>
      <div class="data-div">
        <div class="data-title">References</div>
        <p class="data-info" *ngIf="(user().team_references ?? '') != ''">{{user().team_references}}</p>
        <p class="data-info" *ngIf="(user().team_references ?? '') === ''">--</p>
      </div>
      <div class="data-div">
        <div class="data-title">Additional details</div>
        <p class="data-info" *ngIf="(user().skills_add_comments ?? '') != ''">{{user().skills_add_comments}}</p>
        <p class="data-info" *ngIf="(user().skills_add_comments ?? '') === ''">--</p>
      </div>
    </div>
      <div class="data-div" style="padding-top: 20px;">
        <div class="data-title">Other<div class="data-subtitle">(Other skills and level of experience)</div></div>
        <p class="data-info" *ngIf="(user().skills_text ?? '') != ''">{{user().skills_text}}</p>
        <p class="data-info" *ngIf="(user().skills_text ?? '') === ''">--</p>
      </div>
  </mat-card-content>
</mat-card>