import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrl: './delete-user.component.scss'
})
export class DeleteUserComponent {
  userText: string;
  
  constructor(
    public dialogRef: MatDialogRef<DeleteUserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly userService: UserService,
    private readonly toast: ConfirmationToastComponent,
    private readonly dialogService: DialogService,
  ) {
    dialogRef.disableClose = true;
    this.userText = "Are you sure you want to delete "+this.data.users.map((user: any) => "<b>"+user.first_name+" "+user.last_name+"</b>").join(" and ")+" from the user database?"
  }

  deleteUser(): void {
    this.dialogService.openSpinner()
    this.userService.deleteUser({user_id_list: this.data.users.map((user: { user_id: any; }) => user.user_id)}).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner()
        let userNames = this.data.users.map((user: { first_name: any; }) => user.first_name).join(" and ")
        if (this.data.users.length == 1) {
          userNames += " has"
        } else {
          userNames += " have"
        }
        this.toast.openSuccessSnackBar(`${userNames} been deleted from the database.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openErrorSnackBar("There was an error deleting the user", "Please try deleting the user again.");
        this.dialogRef.close(false);
      },
    })
  }

  close(): void {
    this.dialogRef.close(false);
  }
}