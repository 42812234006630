<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>Merge Fires ({{fires.length}})</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <mat-card appearance="outlined" class="warning">
            <mat-icon class="alert-icon material-icons-round">warning</mat-icon>
            <div class="alert-text">
                <h2>Merge action cannot be undone!</h2>
                <div>Double-check selected fires before merging as they will become one record.</div>
            </div>
        </mat-card>
        <p>The fires selected below will be grouped into one record. The BAER assessment will include all these fires.
        </p>
        <mat-accordion [togglePosition]="'before'">
            <mat-expansion-panel hideToggle="true" #fireTableExp [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="!fireTableExp.expanded">chevron_right</mat-icon>
                        <mat-icon *ngIf="fireTableExp.expanded">expand_more</mat-icon>
                        Selected Fires ({{fires.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="fires">
                    <ng-container matColumnDef="baer_name">
                        <th mat-header-cell *matHeaderCellDef>Fire/Complex Name</th>
                        <td mat-cell *matCellDef="let fire">{{fire.baer_name}}</td>
                        <td mat-footer-cell *matFooterCellDef>Total Acres</td>
                    </ng-container>
                    <ng-container matColumnDef="forestOrgCode">
                        <th mat-header-cell *matHeaderCellDef>ForestOrg Code</th>
                        <td mat-cell *matCellDef="let fire">{{fire.forest_org_code}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="containment_date">
                        <th mat-header-cell *matHeaderCellDef>Containment Date (GIS)</th>
                        <td mat-cell *matCellDef="let fire">{{fire.containment_date | date:"MM/dd/YYYY" }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="est_nfs_acres">
                        <th mat-header-cell *matHeaderCellDef>Est. NFS Acres</th>
                        <td mat-cell *matCellDef="let fire">{{fire.acres_fsonly | number: '1.0-0'}}</td>
                        <td mat-footer-cell *matFooterCellDef> {{est_nfs_acres_total | number: '1.0-0'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="fire_acres">
                        <th mat-header-cell *matHeaderCellDef>Total Fire Acres</th>
                        <td mat-cell *matCellDef="let fire">{{fire.poly_acres | number: '1.0-0'}}</td>
                        <td mat-footer-cell *matFooterCellDef> {{total_fire_acres_total | number: '1.0-0'}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>

        <form id="filter_form" class="filter_form" [formGroup]="mergeForm">
            <p class="sub_title">New Merged Fire Details</p>
            <p>Please update the merged fire information.</p>
            <mat-form-field appearance="outline">
                <mat-label>Merged Fire/Complex Name</mat-label>
                <input matInput formControlName="fire_complex_name" required>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Containment Date</mat-label>
                <input matInput formControlName="containment_date" [matDatepicker]="picker"
                    placeholder="MM/DD/YYYY">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="est_containment_date" [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>Source: 209 report</mat-hint>
                <mat-icon matTooltip="Enter a date only if different from the Containment Date (GIS)." matTooltipClass="tooltipClass"
                    class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Assessment Status</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="null">--</mat-option>
                    @for (status of status_list; track status) {
                    <mat-option [value]="status.value">{{status.text}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Complexity</mat-label>
                <mat-select formControlName="complexity">
                    <mat-option [value]="null">--</mat-option>
                    @for (complexity of complexities; track complexity) {
                    <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon matTooltip="Learn more about BAER Assessment Complexity Levels." matTooltipClass="tooltipClass"
                    (click)="complexityModal($event)" onKeyUp="complexityModal($event)" (keyup.enter)="complexityModal($event)" focusable
                    class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Est. NFS Acres</mat-label>
                <input matInput formControlName="est_nfs_acres" id='nfsAcres' required>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button class="usa-button" [disabled]="!mergeForm.valid" (click)="merge()">Merge Fires</button>
        </div>
    </div>
</div>