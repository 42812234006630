import { Pipe } from "@angular/core";

@Pipe({ name: "bool_yes_no" })
export class BoolPipe {
    transform(rawBool: boolean): string {
        let resp = '';
    if (rawBool) {
        resp = 'Yes';
    } else {
        resp = 'No';
    }
    return resp;
    }
}