import { AfterViewChecked, Component, ElementRef, input, model, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith } from 'rxjs';
import { Validators } from 'ngx-editor';


@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrl: './location-filter.component.scss'
})
export class LocationFilterComponent implements OnInit, AfterViewChecked, OnChanges {
  location = model<any>();
  regions = input<any>();
  roles = input<any>();
  user_type = input<any>();
  BAER_role_type = input<any>();
  positions?: number[];
  forest_names: any;
  selectedForestFlag = false;

  org_location = input<any>();
  org_region = input<any>();
  org_forest = input<any>();
  duty_location = input<any>();
  duty_region = input<any>();
  duty_forest = input<any>();

  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;


  positionForm = this.formBuilder.group({
    location_shared: [0],
    location_id: [-1],
    region: [""],
    forest_name: [""],
    positions: [],
    lo_position: [null]
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentService
  ) {
    this.forest_names = this.assessmentService.getForestNameList();
    this.filtered_forest_names = this.positionForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnInit(): void {
    this.fillUserData();
    this.positionForm.valueChanges.subscribe(value => {
      this.location().lo_position = value.lo_position;
      this.location().location_id = value.location_id;
      if (this.user_type() == 'Coordinator')
        this.location().positions = value.positions;
      else
        this.location().positions = [Number(value.lo_position)];
      this.location().region = value.region;
      this.location().forest_name = value.forest_name;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    let changed = Object.keys(changes)[0];
    if (this.positionForm.value.location_shared == 0) {
      switch (changed) {
        case 'org_location':
          this.positionForm.patchValue({
            positions: undefined
          });
          this.positionForm.patchValue({
            location_id: this.org_location()
          });
          break;
        case 'org_region':
          this.positionForm.patchValue({
            region: this.org_region()
          });
          break;
        case 'org_forest':
          this.positionForm.patchValue({
            forest_name: this.org_forest()
          });
          break;
      }
    }
    if (this.positionForm.value.location_shared == 1) {
      switch (changed) {
        case 'duty_location':
          this.positionForm.patchValue({
            positions: undefined
          });
          this.positionForm.patchValue({
            location_id: this.duty_location()
          });
          break;
        case 'duty_region':
          this.positionForm.patchValue({
            region: this.duty_region()
          });
          break;
        case 'duty_forest':
          this.positionForm.patchValue({
            forest_name: this.duty_forest()
          });
          break;
      }
    }
    this.updateValidators(null, null);
  }

  ngAfterViewChecked(): void {
    this.location().valid = this.positionForm.valid;
  }

  fillUserData(): void {
    this.positionForm.patchValue(
      {
        location_shared: this.location().location_shared,
        positions: this.location().revelantRoles,
        lo_position: this.location().revelantRoles[0],
      }
    )
    this.updateValidators(this.location().location_shared, 'location_shared');
    this.positionForm.patchValue(
      {
        location_shared: this.location().location_shared,
        positions: this.location().revelantRoles,
        lo_position: this.location().revelantRoles[0],
      }
    )
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  regionChanged(): void {
    this.positionForm.patchValue({ forest_name: undefined })
    this.forest_names = this.assessmentService.getForestNameList(this.positionForm.value.region ?? undefined);
    this.filtered_forest_names = this.positionForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }


  updateValidators(value: any, type: any) {
    if (type == 'location_shared') {
      if (value == 0) {
        if (this.positionForm.value.location_id != value)
          this.positionForm.patchValue({
            positions: undefined
          })
        this.positionForm.patchValue({
          location_id: this.org_location(),
          region: this.org_region(),
          forest_name: this.org_forest()
        })
      } else if (value == 1) {
        if (this.positionForm.value.location_id != value)
          this.positionForm.patchValue({
            positions: undefined
          })
        this.positionForm.patchValue({
          location_id: this.duty_location(),
          region: this.duty_region(),
          forest_name: this.duty_forest()
        })
      } else {
        this.positionForm.patchValue({
          location_id: this.location().location_id,
          region: this.location().region,
          forest_name: this.location().forest_name
        })
      }
    }

    let location_id = this.positionForm.controls.location_id.value;
    if (type == 'location_id') {
      location_id = value;
      this.positionForm.patchValue({
        positions: undefined
      })
    }
    this.updateValidatorsCheck(location_id, 0);
  }

  updateValidatorsCheck(location_id: any, agency_id: any): void {
    if (location_id != 0) {
      this.positionForm.controls.forest_name.clearValidators();
      this.positionForm.controls.forest_name.setValue('');

      if (location_id != 1) {
        this.positionForm.controls.region.clearValidators();
        this.positionForm.controls.region.setValue(null);
      }
    }
    if (agency_id != 0 && location_id == 2) {
      this.location().location_id = null;
    }
    let type: string | null = null;
    if (location_id == 0) {
      type = 'FO';
      this.location().filtered_roles = this.roles().filter((r: any) => r.type == 'FOCO');
    }
    else if (location_id == 1) {
      type = 'RO';
      this.location().filtered_roles = this.roles().filter((r: any) => r.type == 'ROCO');
    }
    else if (location_id == 2) {
      type = 'WO';
      this.location().filtered_roles = this.roles().filter((r: any) => r.type == 'WOCO');
    }
    // update position list
    if (this.user_type() == 'Coordinator') {
      type += 'CO';
      this.location().filtered_roles = this.roles().filter((r: any) => r.type == type);
    }

    else if (this.user_type() == 'Line Officer') {
      type += 'LO';
      this.location().filtered_roles = this.roles().filter((r: any) => r.type == type);
      if (!this.location().filtered_roles.map((role: any) => role.value).includes(this.positionForm.value.lo_position)) {
        this.positionForm.patchValue({ lo_position: null })
      }
    }
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.positionForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }

  forestNameSelected(event: any): void {
    this.selectedForestFlag = true
  }
}
