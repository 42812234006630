<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 *ngIf="data.demobilize == true">Demobilize user</h1>
        <h1 *ngIf="data.demobilize != true">Mobilize user</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p *ngIf="data.demobilize == true">Are you sure you want to Demobilize <b>{{data.user_name}}</b>
            from <b>{{data.report_name}}?</b></p>
        <p *ngIf="data.demobilize != true">Are you sure you want to Mobilize <b>{{data.user_name}}</b>
            back into <b>{{data.report_name}}?</b></p>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button remove" [mat-dialog-close]="1"
                *ngIf="data.demobilize == true"><fa-icon class="fa-sm"
                    [icon]="faArrowRight"></fa-icon>Demobilize</button>
            <button aria-label="Update" class="usa-button remove" [mat-dialog-close]="1"
                *ngIf="data.demobilize != true"><fa-icon class="fa-sm" [icon]="faArrowLeft"></fa-icon>Mobilize</button>
        </div>
    </div>
</div>