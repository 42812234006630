<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>Add New Fire</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="newFireForm">
            <mat-form-field appearance="outline">
                <mat-label>Fire or Complex Name</mat-label>
                <input matInput formControlName="fire_complex_name" required aria-label="Fire or Complex Name">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Region</mat-label>
                <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                    @for (region of regions; track region) {
                    <mat-option [value]="region">{{region}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Forest Name</mat-label>
                <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                (input)="filter()"
                (focus)="clearForest()" aria-label="Forest Name">
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="forestNameSelected($event)">
                    @for (name of filtered_forest_names | async; track name) {
                    <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                    }
                </mat-autocomplete>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>ForestOrgCode</mat-label>
                <mat-select formControlName="forestOrgCode" required 
                (selectionChange)="orgCodeSelected($event)" (keydown)="orgCodeKeyPress($event)">
                    @for (orgCode of orgCodes; track orgCode) {
                    <mat-option [value]="orgCode">{{orgCode}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated NFS Acres</mat-label>
                <input matInput formControlName="est_nfs_acres" required id='nfsAcres' aria-label="Estimated NFS Acres">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Containment Date</mat-label>
                <input matInput formControlName="containment_date" [matDatepicker]="picker"
                    placeholder="MM/DD/YYYY" aria-label="Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="est_containment_date" [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>Source: 209 report</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Assessment Status</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="null">--</mat-option>
                    @for (status of status_list; track status) {
                    <mat-option [value]="status.value">{{status.text}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Complexity</mat-label>
                <mat-select formControlName="complexity">
                    <mat-option [value]="null">--</mat-option>
                    @for (complexity of complexities; track complexity) {
                    <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon matTooltip="Learn more about BAER Assessment Complexity Levels." matTooltipClass="tooltipClass"
                    (click)="complexityModal($event)" onKeyUp="complexityModal($event)" (keyup.enter)="complexityModal($event)" focusable
                    class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button class="usa-button" [disabled]="!newFireForm.valid" (click)="addNewFire()">Save</button>
        </div>
    </div>
</div>