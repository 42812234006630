<div class="container">
    <div class="subcontainer">
        <i class="material-icons error-icon">error</i>
        <div>
            <span class="title">
                {{ data.title }}
            </span>
            <br *ngIf="data.message">
            <span class="description">
                {{ data.message }}
            </span>
        </div>
    </div>
    <button mat-icon-button class="exit_button close-icon" (click)="snackbarRef.dismiss()">
        <mat-icon>close</mat-icon>
    </button>
</div>