<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1>Edit BAER Team Needs</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="fireForm">
            <div class="div-label-block">
                <h2>FIRE DETAILS</h2>
                <mat-label class="title">Fire/Complex name</mat-label>
                <mat-label>{{data.fireRecord.baer_name}}</mat-label>
                <mat-label class="title">Forest name</mat-label>
                <mat-label>{{data.fireRecord.forest_name | forest_filter}}</mat-label>
                <mat-label class="title">Region</mat-label>
                <mat-label>{{data.fireRecord.region}}</mat-label>
                <mat-label class="title">Complexity</mat-label>
                <mat-label class="chip-label" [ngSwitch]="data.fireRecord.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </mat-label>
                <mat-label class="title">Assessment Status</mat-label>
                <mat-label class="chip-label">
                    <mat-chip [ngClass]="'chip-'+data.fireRecord.assessment_status_id">
                        {{data.fireRecord.assessment_status_id | statusBadge}}
                    </mat-chip>
                </mat-label>
                <mat-label class="title"></mat-label>
                <mat-label></mat-label>
            </div>
            <div class="long-field-container">
                <mat-form-field appearance="outline" class="third-field">
                    <mat-label>BAER Team Needs</mat-label>
                    <mat-select formControlName="team_needs_id">
                        @for (need of team_needs; track need) {
                        <mat-option [value]="need.value">{{need.text}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                    <mat-icon
                        matTooltip="Prioritize local coverage for your team, then regional. If interregional support is needed, submit an interregional support request from the fire's action menu."
                        matTooltipClass="tooltipClass" class="info-blue fa-regular fa-circle-info"
                        aria-label="Info">info_outline</mat-icon>
                <mat-form-field appearance="outline" class="third-field">
                    <mat-label>Tentative BAER Start Date</mat-label>
                    <input matInput formControlName="tentative_start_week" [matDatepicker]="picker1"
                        placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" class="third-field">
                    <mat-label>Team Close-out Date</mat-label>
                    <input matInput formControlName="close_out_date" [matDatepicker]="picker2" placeholder="MM/DD/YYYY"
                        aria-label="Estimated Containment Date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="support-warning" *ngIf="data.fireRecord.support && fireForm.value.teamLeads == null && !is_RO">
                <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
                <div class="support-text">
                    <span class="support-title">
                        Interregional Support Request
                    </span>
                    <br>
                    <span class="support-description">
                        A <b>team lead</b> needs to be assigned. Select a different <b>region</b> to search for a
                        <b>team lead.</b>
                    </span>
                </div>
            </div>
            <div class="title">BAER Team Lead</div>
            <div *ngIf="!leadDisabled && !interregionalDisabled" class="subtitle">
                Use the filters below and select a primary team lead for the assessment:
                <span><br>If team leads are not available in your region, select <b>Interregional Support</b> from the
                    fire's <b>Actions</b> menu and submit a request for a team lead to be assigned to this BAER
                    assessment.</span>
            </div>

            <div class="support-warning" *ngIf="leadDisabled && !interregionalDisabled" style="height: 110px;">
                <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
                <div class="support-text">
                    <span class="support-title">
                        Report Started
                    </span>
                    <br>
                    <span class="support-description">
                        <b>Team Lead:</b> {{getLeadName()}}
                        <br>
                        A report under this fire has been created. To make any team roster updates, go to the fire's “Teams” tab.
                    </span>
                </div>
            </div>

            <div class="support-warning" *ngIf="!is_WO && interregionalDisabled" style="height: 150px; padding-top: 10px;">
                <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
                <div class="support-text">
                    <span class="support-title">
                        Team lead is from a different region and can't be modified.
                    </span>
                    <br>
                    <span class="support-description" *ngIf="!leadDisabled">
                        <b>Team Lead:</b> {{getLeadName()}}
                        <br>
                        <ul style="max-width: 670px;">
                            <li>Team leader has been assigned through an interregional Support Request by the WO.</li>
                            <li>A report must be created by the team lead, before you can modify or add additional team leads from the "Teams" tab.</li>
                        </ul>
                         
                    </span>
                    <span class="support-description" *ngIf="leadDisabled">
                        <b>Team Lead:</b> {{getLeadName()}}
                        <br>
                        <ul>
                            <li>Team leader has been assigned through an interregional Support Request by the WO.</li>
                            <li>A report under this fire has been created, and a team can now be assembled.</li>
                            <li>To make any team roster updates, go to the fire's "Teams" tab.</li>
                        </ul>
                    </span>
                </div>
            </div>

            <form *ngIf="!is_WO && !leadDisabled && !interregionalDisabled" id="filter_form" class="filter_form"
                [formGroup]="fireForm">
                <div class="whole-line" style="margin-bottom: -15px">
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>First or Last Name</mat-label>
                        <input matInput formControlName="user_name" cdkFocusInitial (input)="filterUsers()">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill(s) needed</mat-label>
                        <mat-select formControlName="search_skills" multiple (selectionChange)="filterUsers()">
                            @for (skill of skillList; track skill) {
                            <mat-option [value]="skill.name">{{skill.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill Level</mat-label>
                        <mat-select formControlName="skill_level" multiple (selectionChange)="filterUsers()">
                            @for (exp of expList; track exp) {
                            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <form *ngIf="is_WO && !leadDisabled && !interregionalDisabled" id="filter_form" class="filter_form"
                [formGroup]="fireForm">
                <div class="whole-line" style="margin-bottom: -15px">
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Region</mat-label>
                        <mat-select formControlName="region" (selectionChange)="filterUsers()">
                            @for (region of regions; track region) {
                            <mat-option [value]="region">{{region}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="two-thirds-field">
                        <mat-label>First or Last Name</mat-label>
                        <input matInput formControlName="user_name" cdkFocusInitial (input)="filterUsers()">
                    </mat-form-field>
                </div>
                <div class="whole-line" style="margin-bottom: -15px">
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill(s) needed</mat-label>
                        <mat-select formControlName="search_skills" multiple (selectionChange)="filterUsers()">
                            @for (skill of skillList; track skill) {
                            <mat-option [value]="skill.name">{{skill.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill Level</mat-label>
                        <mat-select formControlName="skill_level" multiple (selectionChange)="filterUsers()">
                            @for (exp of expList; track exp) {
                            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Availability</mat-label>
                        <mat-select formControlName="availability" multiple (selectionChange)="filterUsers()">
                            <mat-option [value]="'region'">
                                Available Regionally
                            </mat-option>
                            <mat-option [value]="'nation'">
                                Available Nationally
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div *ngIf="!leadDisabled && !interregionalDisabled" class="whole-line">
                <div class="title">{{ leadList.data.length }} team lead{{ leadList.data.length == 1 ? "" : "s"}}
                    <span *ngIf="!is_WO">&nbsp;| Region {{ data.fireRecord.region }}</span>
                </div>
            </div>
            <div *ngIf="!leadDisabled && !interregionalDisabled" class="table-container">
                <table mat-table [dataSource]="leadList" class="center" matSort matSortDisableClear aria-hidden="false">
                    <form class="filter_form" [formGroup]="fireForm">
                        <mat-radio-group formControlName="radio">
                            <ng-container matColumnDef="name" sticky>
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

                                <td class="user-cell" mat-cell *matCellDef="let user"
                                    [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                                    <div style="display:flex">
                                        <mat-radio-button [value]="user" color="primary" class="radio-button"
                                            (change)="radioChange($event)"
                                            [checked]="user.user_id == fireForm.value.teamLeads"></mat-radio-button>
                                        <div class="user-cell-name">
                                            <div class="user-icon vertical-center">
                                                <p>{{user.first_name[0]}}</p>
                                            </div>
                                            <p class="user-name-2">{{user.first_name + " " + user.last_name}}</p>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                        </mat-radio-group>
                    </form>
                    <ng-container matColumnDef="skills" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>Skills</th>
                        <td mat-cell *matCellDef="let user"
                            [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                            <mat-chip class="chip"
                                *ngIf="user.skills.length != 0; else skillDef">{{user.skills[0].skill.text}}</mat-chip>
                            <ng-template #skillDef>--</ng-template>
                            <mat-chip class="chip" *ngIf="user.skills.length > 1" style="margin-left: 5px;"
                                [matTooltip]='user.tooltip' matTooltipClass="tooltipClass">
                                +{{user.skills.length - 1}}
                            </mat-chip>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignment_status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 280px;">Fire Assignment
                            Status</th>
                        <td mat-cell *matCellDef="let user"
                            [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                            <div class="available"
                                *ngIf="(user.available_personal ?? true) == false; else availableCheck">
                                <mat-icon class="icon_not">block</mat-icon> Not Available
                            </div>
                            <ng-template #availableCheck>
                                <div class="available" *ngIf="user.filteredAssessment; else availableDef">
                                    <div class="available_row1"><fa-icon [icon]="faFire"></fa-icon><b
                                            style="margin-right: 5px;">Fire:</b>{{user.filteredAssessment.baer_name}}
                                    </div>
                                    <div class="available_row2">
                                        <mat-icon>event_note</mat-icon>
                                        <b>Start: </b>{{user.filteredAssessment.tentative_start_week |
                                        date:"MM/dd/YYYY"}}
                                        <b>Close: </b>{{user.filteredAssessment.close_out_date | date:"MM/dd/YYYY"}}
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #availableDef>
                                <div class="available">
                                    <mat-icon class="icon_available">check</mat-icon> Available
                                </div>
                            </ng-template>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true" class="header-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6">
                            <div class="no-data">
                                <div class="no-data-icon">
                                    <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
                                </div>
                                <div class="no-data-title">No team leaders available</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="whole-line-column">
                <div class="title">Are you willing to accept trainees?<span class="gray-text">List trainee positions
                        below if known.</span></div>
                <mat-radio-group formControlName="trainees_needed" class="radio-buttons">
                    <mat-radio-button [value]="true" color="primary" class="radio-button">Yes</mat-radio-button>
                    <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="whole-line-column">
                <div class="title">Notes<span class="gray-text">(Concerns, highlights, staffing needs)</span>
                </div>
                <mat-form-field class="text-area" appearance="outline">
                    <textarea matInput formControlName="notes" placeholder="Enter description"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid"
                (click)="editAssessment()">Save</button>
        </div>
    </div>
</div>