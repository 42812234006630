import { Component, input, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})


export class SubmissionComponent implements OnChanges {
  report = input.required<any>();
  is_co = input.required<boolean>();
  nav = input.required<string>();

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    this.titleService.setTitle("BAER - Report Submitted")
  }
  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    if (this.nav() == 'close') {
      this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
    }
  }
}
