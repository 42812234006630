<div class="report-header" *ngIf="report">
    <div class="rh-left">
        <div class="rh-title">{{report.baer_name}} ({{report.report_num}})</div>
        <div class="rh-sub">
            2500-8 Report ID: {{report.report_id}}
            <span class="pipe-color"> | </span>
            Action: @if(report.report_num == 1){Initial}@else{Interim}
            <span class="pipe-color"> | </span>
            Report
            Status: <mat-chip [ngClass]="'chip-r-'+report.report_status_id">{{report.report_status_id |
                reportStatusBadge}}</mat-chip>
        </div>
    </div>
    <div class="rh-right">
        <button (click)="save_close()" style="height: 40px;font-weight: 700;" mat-flat-button color="accent"
            *ngIf="report_page != 9">Save & Close</button>
        <button (click)="save_close()" style="height: 40px;font-weight: 700;" mat-flat-button color="accent"
            *ngIf="report_page == 9">Close</button>
    </div>
</div>
<div class="report-main" *ngIf="report">
    <div class="report-nav" *ngIf="report_page != 9">
        <div class="rnav-header">
            <div>2500-8 Report</div>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_1_status_id +' rnav-current-'+(report_page==1)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 1}">
                <div>
                    @if(report.page_1_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_1_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    1. Report Type
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_2_status_id +' rnav-current-'+(report_page==2)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 2}">
                <div>
                    @if(report.page_2_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_2_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    2. Burned Area Description
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_3_status_id +' rnav-current-'+(report_page==3)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 3}">
                <div>
                    @if(report.page_3_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_3_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    3. Watershed Condition
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_4_status_id +' rnav-current-'+(report_page==4)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 4}">
                <div>
                    @if(report.page_4_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_4_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    4. Summary of Analysis
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_5_status_id +' rnav-current-'+(report_page==5)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 5}">
                <div>
                    @if(report.page_5_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_5_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    5. Recommended Treatment Narratives
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_6_status_id +' rnav-current-'+(report_page==6)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 6}">
                <div>
                    @if(report.page_6_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_6_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    6. Emergency Stabilization Treatments & Requested Funds
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_7_status_id +' rnav-current-'+(report_page==7)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 7}">
                <div>
                    @if(report.page_7_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_7_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    7. Team & Supporting Documents
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report.page_8_status_id +' rnav-current-'+(report_page==8)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 8}">
                <div>
                    @if(report.page_8_status_id == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report.page_8_status_id == 2) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    8. Select Forest Unit Line Officer(s)
                </div>
            </a>
        </div>
    </div>
    <div class="report-right-area">
        <div class="report-area">
            <div class="report-section" *ngIf="report">
                @switch (report_page) {
                @case (1) {<app-report-type [report]="report" [nav]="nav"></app-report-type>}
                @case (2) {<app-burned-area [report]="report" [nav]="nav"></app-burned-area>}
                @case (3) {<app-watershed-condition [report]="report" [nav]="nav"></app-watershed-condition>}
                @case (4) {<app-analysis-summary [report]="report" [nav]="nav"></app-analysis-summary>}
                @case (5) {<app-recommended-treatment [report]="report" [nav]="nav"></app-recommended-treatment>}
                @case (6) {<app-emergency-stabilization [report]="report" [nav]="nav"></app-emergency-stabilization>}
                @case (7) {<app-team-supporting [report]="report" [nav]="nav"></app-team-supporting>}
                @case (8) {<app-select-line-officer [report]="report" [nav]="nav"
                    [is_co]="is_co"></app-select-line-officer>}
                @case (9) {<app-submission [report]="report" [nav]="nav" [is_co]="is_co"></app-submission>}
                @default {<div>No Report Page exists for this ID. please try again. {{report_page}}</div>}
                }
            </div>
            <div class="report-comments" [@expand]="comment_expand" *ngIf="report_page != 9">
                @if(comments_unresolved) {
                <button mat-icon-button aria-label="chat_button" class="icon-button" (click)="comment_expand = true"
                    *ngIf="!comment_expand">
                    <fa-icon [icon]="faMessage" class="chat-count-red" aria-label="chat"></fa-icon><span
                        class="button-text">{{comments?.length ?? 0}}</span>
                </button>
                } @else {
                <button mat-icon-button aria-label="chat_button" class="icon-button" (click)="comment_expand = true"
                    *ngIf="!comment_expand">
                    <fa-icon [icon]="faMessage" class="chat-count" aria-label="chat"></fa-icon><span
                        class="button-text">{{comments?.length ?? 0}}</span>
                </button>
                }
                <div class="comment-header" *ngIf="comment_expand">
                    <button class="comment-button">
                        Comments<span>({{comments?.length ?? 0}})</span></button>
                    <button class="comment-add-button" *ngIf="comment_expand && is_co" (click)="addComment()"
                        matTooltip="Add comment" matTooltipClass="tooltipClass">
                        <mat-icon>add</mat-icon></button>
                    <button mat-icon-button class="icon-button" (click)="comment_expand = false"
                        matTooltip="Hide comments" matTooltipClass="tooltipClass"
                        matTooltipPosition="left"><mat-icon>keyboard_tab</mat-icon></button>
                </div>
                <div class="no-comments" *ngIf="comment_expand && ((comments?.length ?? 0) == 0)">
                    <fa-icon [icon]="faCommentSlash" class="chat-count" aria-label="no messages"></fa-icon>
                    <div class="no-comments-title">No Comments</div>
                    <div class="no-comments-text" *ngIf="!is_co">
                        No revisions have been requested
                    </div>
                    <div class="no-comments-text" *ngIf="is_co">
                        Leave a comment to request revisions to the report
                    </div>
                    <button class="comment-add-button" *ngIf="comment_expand && is_co"
                        (click)="addComment()"><mat-icon>add</mat-icon> Add Comment</button>
                </div>
                <div class="comments" *ngIf="comment_expand && ((comments?.length ?? 0) > 0)">
                    @for(comment of comments; track comment) {
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>
                                <a [routerLink]="'/2500-8-report'"
                                    [queryParams]="{report_id: report.report_id, report_page_input: comment.section}">
                                    {{comment.section}}. {{findPage(comment.section) }}
                                </a>
                            </mat-card-title>
                            <div class="comment-title-align">
                                <mat-icon class="resolved" matTooltip="Resolved" matTooltipClass="tooltipClass"
                                    matTooltipPosition="left" *ngIf="comment.resolved">check</mat-icon>
                                <button class="comment-menu" mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Comment Menu" matTooltip="Actions menu" matTooltipClass="tooltipClass"
                                    matTooltipPosition="left">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button class="comment-edit" mat-menu-item (click)="editComment(comment)">
                                        <fa-icon class="fa-sm" [icon]="faEdit" style="color: #216E1F;"></fa-icon>
                                        <span>Edit</span>
                                    </button>
                                    <button class="comment-delete" mat-menu-item (click)="deleteComment(comment)">
                                        <fa-icon class="fa-sm" [icon]="faTrash"></fa-icon>
                                        <span>Delete</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </mat-card-header>
                        <mat-card-content>
                            <span class="comment-info"><b>{{comment.commentator_name}}</b> • {{comment.updated_on |
                                date:"MM/dd/YYYY"}}</span>
                            <span class="comment-detail">{{comment.comment}}</span>
                        </mat-card-content>
                    </mat-card>
                    }
                </div>
            </div>
        </div>
        <div class="report-footer" *ngIf="report_page != 9">
            <div class="rf-left">
                @switch (report_page) {
                @case (1) {}
                @case (2) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>1. Report Type</a>}
                @case (3) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>2. Burned Area Description</a>}
                @case (4) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>3. Watershed Condition</a>}
                @case (5) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>4. Summary of Analysis</a>}
                @case (6) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>5. Recommended Treatment
                    Narratives</a>}
                @case (7) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>6. Emergency Stabilization Treatments
                    & Requested Funds</a>}
                @case (8) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>7. Team & Supporting Documents</a>}
                @default {}
                }

            </div>
            <div class="rf-right">
                @switch (report_page) {
                @case (1) {<a (click)="forward()">2. Burned Area Description<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (2) {<a (click)="forward()">3. Watershed Condition<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (3) {<a (click)="forward()">4. Summary of Analysis<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (4) {<a (click)="forward()">5. Recommended Treatment
                    Narratives<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (5) {<a (click)="forward()">6. Emergency Stabilization Treatments & Requested
                    Funds<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (6) {<a (click)="forward()">7. Team & Supporting
                    Documents<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (7) {<a (click)="forward()">8. Select Forest Unit Line
                    Officer(s)<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (8) {
                <button class="submit-btn" (click)="forward()" *ngIf="!is_co" mat-flat-button color="accent" 
                [disabled]="
                report.page_1_status_id != 2 ||
                report.page_2_status_id != 2 ||
                report.page_3_status_id != 2 ||
                report.page_4_status_id != 2 ||
                report.page_5_status_id != 2 ||
                report.page_6_status_id != 2 ||
                report.page_7_status_id != 2 ||
                report.page_8_status_id != 2
                ">Send for Review
                    <mat-icon>send</mat-icon></button>
                <button class="submit-btn" (click)="forward()" *ngIf="is_co"
                    matTooltip="All feedback must be fixed and completed before requesting a final report."
                    matTooltipClass="tooltipClass">Request Final Report
                    <mat-icon>send</mat-icon></button>
                }
                @default {}
                }
            </div>
        </div>
    </div>
</div>