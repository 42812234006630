import { Pipe } from "@angular/core";

@Pipe({ name: "phone" })
export class PhonePipe {
    transform(rawNum: string): string {
        if (rawNum == null || rawNum == '--') {
            return rawNum
        } else if (rawNum.length === 0) {
            return rawNum;
        } else if (rawNum.length <= 3) {
            rawNum = rawNum.replace(/^(\d{0,3})/, '($1)');
        } else if (rawNum.length <= 6) {
            rawNum = rawNum.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (rawNum.length <= 10) {
            rawNum = rawNum.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
            rawNum = rawNum.substring(0, 10);
            rawNum = rawNum.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        return rawNum;
    }
}