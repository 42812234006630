import { AfterContentChecked, AfterViewChecked, Component, ElementRef, Inject, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/services/dialog.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrl: './new-user.component.scss'
})
export class NewUserComponent implements AfterContentChecked {
  faPlus = faPlus;
  positionsValid: boolean = false;

  regions: any;
  roles: any;
  filtered_roles: any[] = [];
  forest_names: any;
  forest_names_duty: any;
  filtered_forest_names: Observable<string[]>;
  filtered_forest_names_duty: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  agency_list: any;
  enteringEmail = true;
  foundUser: any = null;
  canEdit: boolean = true;
  editingUser = false;
  emailInput = new FormControl("", Validators.email);
  user_id: number = -1;
  BAER_role_type: string | undefined = undefined
  selectedForestFlag = false;
  selectedForestFlag_duty = false;

  PositionList: any[] = [];

  userForm = this.formBuilder.group({
    first_name: [""],
    last_name: [""],
    agency_id: [-1, Validators.min(0)],
    region: [""],
    forest_name: [""],
    location_id: [-1],
    duty_region: [""],
    duty_forest_name: [""],
    duty_location_id: [-1],
    email: [""],
    phone_num: [""]
  });

  constructor(
    public dialogRef: MatDialogRef<NewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentService,
    private readonly userService: UserService,
    private readonly toast: ConfirmationToastComponent,
    private readonly dialogService: DialogService,
  ) {
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.forest_names_duty = this.assessmentService.getForestNameList();
    this.agency_list = this.userService.getAgencyList();
    this.userService.getRoleList().subscribe((roles) => { this.roles = roles });

    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )

    this.filtered_forest_names_duty = this.userForm.controls["duty_forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )

    if (data?.user) {
      this.editingUser = true;
      this.enteringEmail = false;
      this.fillUserData(data.user)
    }
    else {
      this.userForm.controls.email.disable();
      this.PositionList.push({ location_id: null, region: null, forest_name: null, positions: [], revelantRoles: [], filtered_roles: null, location_shared: 0, valid: false, item: 0 });
      this.validateLocationRegionForestEditability(null);
    }

    if (data.user_type != 'User') {
      this.userForm.patchValue({
        agency_id: 0
      })
    }
  }

  ngAfterContentChecked(): void {
    let valid = true
    this.PositionList.forEach((x: any) => { if (!x.valid || !valid) valid = false })
    this.positionsValid = valid;
  }

  fillUserData(user: any): void {
    this.userForm.patchValue(
      user
    )
    this.validateLocationRegionForestEditability(user);
    this.user_id = user.user_id;
    if (this.data.user_type == 'Line Officer') {
      this.BAER_role_type = "LO"
    } else if (this.data.user_type == 'Coordinator') {
      this.BAER_role_type = "CO"
    }
    if ((this.data.user_type == 'Line Officer' || this.data.user_type == 'Coordinator') && user.agency_id === null) {
      this.userForm.patchValue({
        agency_id: 0
      })
    }

    if (this.BAER_role_type) {
      let revelantRoles = user.baer_roles.filter(
        (role: any) => (role.baer_role.type || "").slice(-2) == this.BAER_role_type
      )
      if (revelantRoles.length > 0)
        this.loadPositionList(revelantRoles);
    }
    this.updateValidators(null, null)
  }

  loadPositionList(revelantRoles: []) {
    let orgPosList: number[] = [];
    let dutyPosList: number[] = [];
    revelantRoles.forEach((role: any) => {
      if (role.location_id == null) {
        role.location_id = this.userForm.controls.location_id.getRawValue();
        role.region = this.userForm.controls.region.getRawValue();
        role.forest_name = this.userForm.controls.forest_name.getRawValue();
      }
      if (role.location_id == this.userForm.controls.location_id.getRawValue()
        && role.region == this.userForm.controls.region.getRawValue()
        && role.forest_name == this.userForm.controls.forest_name.getRawValue()) {
        orgPosList.push(role.baer_role.value);
      } else if (role.location_id == this.userForm.controls.duty_location_id.getRawValue()
        && role.region == this.userForm.controls.duty_region.getRawValue()
        && role.forest_name == this.userForm.controls.duty_forest_name.getRawValue()) {
        dutyPosList.push(role.baer_role.value);
      } else {
        this.PositionList.push({ location_id: role.location_id, region: role.region, forest_name: role.forest_name, positions: [Number(role.baer_role.value)], revelantRoles: [Number(role.baer_role.value)], filtered_roles: null, location_shared: 2, valid: false, item: this.PositionList.length });
      }
    });
    if (orgPosList.length > 0)
      this.PositionList.push({ location_id: null, region: null, forest_name: null, positions: orgPosList, revelantRoles: orgPosList, filtered_roles: null, location_shared: 0, valid: false, item: this.PositionList.length });
    if (dutyPosList.length > 0)
      this.PositionList.push({ location_id: null, region: null, forest_name: null, positions: dutyPosList, revelantRoles: dutyPosList, filtered_roles: null, location_shared: 1, valid: false, item: this.PositionList.length });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  private _filter_duty(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names_duty.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  filter_duty(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names_duty = of(this._filter_duty(filterValue));
  }

  saveUser(): void {
    let user_baer_roles: any[] = []
    let procedure = 'NEW_TO_COORDINATION';
    if (this.userForm.value.location_id == 2) {
      this.userForm.patchValue({
        region: "WO"
      })
    } else if (this.userForm.value.location_id == -1) {
      this.userForm.patchValue({
        location_id: undefined
      })
    }
    if (this.userForm.value.duty_location_id == 2) {
      this.userForm.patchValue({
        duty_region: "WO"
      })
    } else if (this.userForm.value.duty_location_id == -1) {
      this.userForm.patchValue({
        duty_location_id: undefined
      })
    }
    if (this.data.user_type == 'Line Officer') {
      procedure = 'NEW_TO_LINE_OFFICERS';
    }
    if (this.data.user_type == 'User') {
      user_baer_roles.push({
        baer_role_id: 2,
        region: this.userForm.getRawValue().region ?? '',
        forest_name: this.userForm.getRawValue().forest_name ?? '',
        location_id: this.userForm.getRawValue().location_id
      })
      procedure = 'NEW_TO_ALL';
    }
    if (this.PositionList.length > 0) {
      this.PositionList.forEach((position: any) => {
        position.positions.forEach((x: any) => {
          user_baer_roles.push({
            baer_role_id: x,
            region: position.region,
            forest_name: position.forest_name,
            location_id: position.location_id
          })
        });
      })
    }

    if (this.editingUser) {
      procedure = 'REASSIGN';
      this.userService.editUserDetails({
        user_id: this.user_id,
        first_name: this.userForm.value.first_name!,
        last_name: this.userForm.value.last_name!,
        region: this.userForm.getRawValue().region ?? '',
        forest_name: this.userForm.getRawValue().forest_name ?? '',
        agency_id: this.userForm.value.agency_id,
        location_id: this.userForm.getRawValue().location_id,
        duty_region: this.userForm.getRawValue().duty_region ?? '',
        duty_forest_name: this.userForm.getRawValue().duty_forest_name ?? '',
        duty_location_id: this.userForm.getRawValue().duty_location_id,
        phone_num: this.userForm.value.phone_num ?? undefined,
        email: this.userForm.value.email!,
        user_baer_roles: user_baer_roles,
        BAER_role_type: this.BAER_role_type,
        procedure
      }).subscribe({
        next: (response) => {
          this.toast.openSuccessSnackBar(`${this.userForm.value.first_name} was successfully updated.`);
          this.dialogRef.close(true);
        },
        error: (response) => {
          this.toast.openErrorSnackBar("There was an error updating the user.", "Please try again.");
          this.dialogRef.close();
        },
      })
    } else {
      if (this.userForm.value.phone_num?.length == 0) this.userForm.value.phone_num = undefined;
      this.userService.createNewUser({
        first_name: this.userForm.value.first_name!,
        last_name: this.userForm.value.last_name!,
        region: this.userForm.getRawValue().region ?? undefined,
        forest_name: this.userForm.getRawValue().forest_name ?? undefined,
        agency_id: this.userForm.value.agency_id,
        location_id: this.userForm.getRawValue().location_id,
        duty_region: this.userForm.getRawValue().duty_region ?? undefined,
        duty_forest_name: this.userForm.getRawValue().duty_forest_name ?? undefined,
        duty_location_id: this.userForm.getRawValue().duty_location_id,
        phone_num: this.userForm.value.phone_num,
        email: this.userForm.getRawValue().email!,
        user_baer_roles: user_baer_roles,
        procedure
      }).subscribe({
        next: (response) => {
          this.toast.openSuccessSnackBar(`${this.userForm.value.first_name} was added to the roster.`);
          this.dialogRef.close(true);
        },
        error: (response) => {
          this.toast.openErrorSnackBar("There was an error adding the new user.", "Please try adding the user again.");
          this.dialogRef.close();
        },
      })
    }
  }

  emailInputChanged(): void {
    this.canEdit = true;
    this.foundUser = null;
  }

  // Check if user exists to send to edit page
  checkIfUserExist(): void {
    if (this.foundUser) {
      this.enteringEmail = false;
      this.editingUser = true;
      this.fillUserData(this.foundUser)
      return
    }
    this.dialogService.openSpinner()
    this.userService.getUsersByEmail(this.emailInput.value!).subscribe(data => {
      this.dialogService.closeSpinner()
      let users = data.users
      if (users.length == 0) {
        // No users found, creating new user
        this.userForm.patchValue({
          email: this.emailInput.value
        })
        this.enteringEmail = false;
      } else {
        // A user was found
        // If there happens to be multiple users, just choosing first for now
        this.foundUser = users[0]
        //check if user can be edited
        this.userService.getUserProfile().then(self => {
          const isRocoWoco = !!(self.baer_roles as any[])?.find(user => ['ROCO', 'WOCO'].includes(user.baer_role.type) || user.baer_role.value == 0);
          if (isRocoWoco) { //can always edit
            this.canEdit = true;
          }
          else if (((this.foundUser.forest_name ?? '') == '' && (this.foundUser.region ?? '') == '')
            || (this.foundUser.forest_name == self.forest_name && this.foundUser.region == self.region)) { //if in same forest, can edit
            this.canEdit = true;
          } else {
            this.canEdit = false;
          }
        });
      }
    });
  }

  regionChanged(): void {
    this.userForm.patchValue({ forest_name: undefined })
    this.forest_names = this.assessmentService.getForestNameList(this.userForm.value.region ?? undefined);
    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  regionChanged_duty(): void {
    this.userForm.patchValue({ duty_forest_name: undefined })
    this.forest_names_duty = this.assessmentService.getForestNameList(this.userForm.value.duty_region ?? undefined);
    this.filtered_forest_names_duty = this.userForm.controls["duty_forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter_duty((value as string) || ""))
    )
  }

  updateValidators(value: any, type: any) {
    let location_id = this.userForm.controls.location_id.value;
    let agency_id = this.userForm.value.agency_id;
    if (type == 'location_id') {
      location_id = value;
    }
    if (type == 'agency_id') {
      agency_id = value;
    }
    this.updateValidatorsCheck(location_id, agency_id);
  }

  updateValidatorsCheck(location_id: any, agency_id: any): void {
    if (location_id != 0) {
      this.userForm.controls.forest_name.clearValidators();
      this.userForm.controls.forest_name.setValue('');

      if (location_id != 1) {
        this.userForm.controls.region.clearValidators();
        this.userForm.controls.region.setValue(null);
      }
    }
    if (agency_id != 0 && location_id == 2) {
      this.userForm.controls.location_id.setValue(null);
    }

    let type: string | null = null;
  }

  // if user is team lead and not a ROCO, FOCO or WOCO, user cannot edit location, region and forest
  validateLocationRegionForestEditability(user: any) {
    this.userService.getUserProfile().then(self => {
      const isRocoWoco = !!(self.baer_roles as any[])?.find(user => ['ROCO', 'WOCO'].includes(user.baer_role.type) || user.baer_role.value == 0);
      if (isRocoWoco) {
        return;
      }
      this.userForm.controls.location_id.clearValidators();
      if ((user?.location_id ?? null) === null) this.userForm.controls.location_id.setValue(self.location_id);
      if (self.location_id === 0 || self.location_id === 1) {
        this.userForm.controls.region.clearValidators();
        if ((user?.region ?? null) === null) this.userForm.controls.region.setValue(self.region);
        if (self.location_id === 0) {
          this.userForm.controls.forest_name.clearValidators();
          if ((user?.forest_name ?? null) === null) this.userForm.controls.forest_name.setValue(self.forest_name);
        }
      }
      this.checklocationDuty(user, self)
      this.userForm.controls.location_id.disable();
      this.userForm.controls.region.disable();
      this.userForm.controls.forest_name.disable();
      this.userForm.controls.duty_location_id.disable();
      this.userForm.controls.duty_region.disable();
      this.userForm.controls.duty_forest_name.disable();
      this.updateValidatorsCheck(self.location_id, self.agency_id);
    })
  }

  checklocationDuty(user: any, self: any) {
    if ((user?.duty_location_id ?? null) === null) this.userForm.controls.duty_location_id.setValue(self.duty_location_id);
    if (self.duty_location_id === 0 || self.duty_location_id === 1) {
      this.userForm.controls.duty_region.clearValidators();
      if ((user?.duty_region ?? null) === null) this.userForm.controls.duty_region.setValue(self.duty_region);
      if (self.duty_location_id === 0) {
        this.userForm.controls.duty_forest_name.clearValidators();
        if ((user?.duty_forest_name ?? null) === null) this.userForm.controls.duty_forest_name.setValue(self.duty_forest_name);
      }
    }
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.userForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }

  clearForest_duty() {
    if (!this.selectedForestFlag_duty) {
      this.userForm.patchValue({
        duty_forest_name: ""
      })
    }
    this.selectedForestFlag_duty = false
  }

  forestNameSelected(event: any): void {
    this.selectedForestFlag = true
  }

  forestNameSelected_duty(event: any): void {
    this.selectedForestFlag_duty = true
  }

  addPosition() {
    this.PositionList.push({ location_id: null, region: null, forest_name: null, positions: [], revelantRoles: [], filtered_roles: null, location_shared: 0, valid: false, item: this.PositionList.length });
  }

  removePosition(item: number) {
    this.PositionList = [...this.PositionList.slice(0, item), ...this.PositionList.slice(item + 1)];
  }

  checkPositionValid() {
    return !this.PositionList[0].valid
  }
}
